import { useEffect, useState, useContext } from 'react';
import { AssembleQuery, Restquery } from '../queries/AssembleQuery';
import { Button, Skeleton } from '@mui/material'
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { AlertComponent } from './ErrorModal';
import { UserContext } from '../components/ContextProvider';

function getCurrentDateHyphenFormat() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = currentDate.getDate().toString().padStart(2, '0');

  return `${day}.${month}.${year}`;
}

function getCurrentDateTimeHyphenFormat() {
  const currentDatetime = new Date();
  const isoDatetime = currentDatetime.toISOString();
  return isoDatetime;
}

export const AnwesenheitsButtons = (props) => {
  const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)

  const currentDateForRequest = getCurrentDateHyphenFormat()

  const { execute } = useFetchWithMsal({
    scopes: [protectedResources.Dynamics365.scope]
  });


  const [Anwesenheitsdetail, setAnwesenheitsdetail] = useState(null);
  const [Mitarbeiter_LookUp, setMitarbeiter_LookUp] = useState("/xrm1_employees(" + MitarbeiterGUID + ")");
  const [ErrorComponent, setErrorComponent] = useState(null)

  useEffect(() => {
    if (!Anwesenheitsdetail) {
      let requestParameters = new Restquery()
      requestParameters.EntityName = "xrm1_attendance_details"
      requestParameters.FilterCriteria = "contains(xrm1_name,'" + currentDateForRequest + "') and _xrm1_attendance_detail_employee_id_value eq " + MitarbeiterGUID + " and statecode eq 0"
      requestParameters.OrderByNames = ["xrm1_name"]
    
      let request = AssembleQuery(requestParameters);

      execute("GET", protectedResources.Dynamics365.endpoint + request).then((response) => {
        setAnwesenheitsdetail(response["value"])
      });
    }
  }, [execute, Anwesenheitsdetail])

  const closeCallback = () => {
    setErrorComponent(null)
  }

  const handleClick = (title) => {
    setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit wird erstellt..."} status={"info"}></AlertComponent>)

    if (title == "Arbeitsbeginn") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100000,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Arbeitsende") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100003,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Home-Office antreten") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100005,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Home-Office beenden") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100008,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Pausenbeginn") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100001,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Pausenende") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100002,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Dienstgang antreten") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100006,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Dienstgang beenden") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100009,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Reise antreten") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100004,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
    if (title == "Reise beenden") {
      //Has to be tested
      const payloadData = {
        "xrm1_start_date": getCurrentDateTimeHyphenFormat(),
        "xrm1_type": 693100007,
        "xrm1_attendance_detail_employee_id@odata.bind": Mitarbeiter_LookUp
      }

      execute("POST", protectedResources.Dynamics365.endpoint + "xrm1_attendance_details", payloadData).then((response) => {
        if (response.ok) {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Anwesenheit erfolgreich erstellt"} status={"success"}></AlertComponent>)
          setAnwesenheitsdetail(null)
          props.refreshRequestor({"1": "Anwesenheitsdetails", "2": "Graph", "3": "Anwesenheiten"})
        } else {
          setErrorComponent(<AlertComponent closeCallback={closeCallback} showMo={true} message={"fehler beim erstellen der Anwesenheit"} status={"error"}></AlertComponent>)
        }
      });
    }
  };

  const buttonData = [
    { title: 'Arbeitsbeginn' },
    { title: 'Arbeitsende' },
    { title: 'Home-Office antreten' },
    { title: 'Home-Office beenden' },
    { title: 'Pausenbeginn' },
    { title: 'Pausenende' },
    { title: 'Dienstgang antreten' },
    { title: 'Dienstgang beenden' },
    { title: 'Reise antreten' },
    { title: 'Reise beenden' },
  ];

  const checkDisabled = (title) => {
    if (Anwesenheitsdetail && Anwesenheitsdetail != null) {
      const lastIndex = Anwesenheitsdetail.length - 1;
      if (Anwesenheitsdetail[lastIndex] != undefined) {
        const xrm1_name = Anwesenheitsdetail[lastIndex].xrm1_name

        const regexPattern = new RegExp(".*" + String(Anwesenheitsdetail[0].xrm1_name).replace("beginn ", "").split(" - ")[1] + ".*");
        const regexPattern2 = new RegExp(".*" + String(Anwesenheitsdetail[0].xrm1_name).replace("antreten ", "").split(" - ")[1] + ".*");

        if (String(Anwesenheitsdetail[0].xrm1_name).includes("antreten")) {
          if (!regexPattern2.test(title) && !title.includes("Pause")) {
            return true
          } 
        } else {
          if (!regexPattern.test(title) && !title.includes("Pause")) {
            return true
          } 
        }

        if (xrm1_name.includes("Pausenbeginn") && title.includes("Pausenbeginn")) {
          // Pause was started
          return true
        }
        if (!xrm1_name.includes("Pausenbeginn") && title.includes("Pausenende")) {
          // Pause wasnt started
          return true
        }

        if ((xrm1_name.includes("ende")) && (title.includes("ende") || title.includes("Pause")) && !xrm1_name.includes("Pause")) {
          // Arbeitszeit wasnt started
          return true;
        } else if ((xrm1_name.includes("beginn") || xrm1_name.includes("antreten") || xrm1_name.includes("Pause")) && (title.includes("Arbeitsbeginn") || title.includes("antreten"))) {
          // Arbeitszeit was started
          return true;
        } else {
          return false;
        }


      } else {
        // When no Zeitstempel are existing
        return !!(title.includes("ende") || title.includes("Pause"));
      }
    }
  }

  const createButtonRow = (startIndex) => {
    return (
      <div className="ButtonRow" key={startIndex}>
        <div style={{width: "50%", paddingRight: "7px", textAlign: "right"}}>
          <Button variant="contained" disabled={checkDisabled(buttonData[startIndex].title)} id={buttonData[startIndex].title} onClick={() => handleClick(buttonData[startIndex].title)}>
            {buttonData[startIndex].title}
          </Button>
        </div>
        <div style={{width: "50%"}}>
          <Button variant='contained' disabled={checkDisabled(buttonData[startIndex + 1].title)} id={buttonData[startIndex + 1].title} onClick={() => handleClick(buttonData[startIndex + 1].title)}>
            {buttonData[startIndex + 1].title}
          </Button>
        </div>
      </div>
    );
  };

  let Buttons = Array.from({ length: buttonData.length / 2 }, (_, index) => createButtonRow(index * 2))

  if (Anwesenheitsdetail && Mitarbeiter_LookUp) {
    return (
      <div id="AttendanceButtons">
        {Buttons}
        {ErrorComponent}
      </div>
    );
  } else {
    return (
      <div id="AttendanceButtons">
        <div className="ButtonRow" >
          <div style={{width: "50%", paddingRight: "7px", textAlign: "right"}}>
            <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
          <div style={{width: "50%"}}>
            <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
        </div>
        <div className="ButtonRow" >
          <div style={{width: "50%", paddingRight: "7px", textAlign: "right"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
          <div style={{width: "50%"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
        </div>
        <div className="ButtonRow" >
          <div style={{width: "50%", paddingRight: "7px", textAlign: "right"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
          <div style={{width: "50%"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
        </div>
        <div className="ButtonRow" >
          <div style={{width: "50%", paddingRight: "7px", textAlign: "right"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
          <div style={{width: "50%"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
        </div>
        <div className="ButtonRow" >
          <div style={{width: "50%", paddingRight: "7px", textAlign: "right"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
          <div style={{width: "50%"}}>
          <Skeleton sx={{height: "36.5px"}}></Skeleton>
          </div>
        </div>
      </div>
    )
  }
};