import React, { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { UserContext } from "../components/ContextProvider"
import { Typography, Button, IconButton, Box, Stack } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid"
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';

import '../styles/Anwesenheit.module.css'

import { FlowRuns, RecentlyFailedFlows } from "../components/FlowRuns"

export const AdminPowerAutomate = () => {
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState(new Array)
    const [fetchedFlows, setfetchedFlows] = useState(false)

    const [selectedFlow, setSelectedFlow] = useState(false)
    const [selectedFlowName, setSelectedFlowName] = useState("None")
    
    const [allFlows, setAllFlows] = useState(null)

    const gridApiRef = useGridApiRef()
    
    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });  

    function sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
    }

    const columns = [
        {field: "name", headerName: "Name", width: 400},
        {field: "description", headerName: "Beschreibung", width: 200},
        {field: "statecode_str", headerName: "Status", width: 200},
    ]

    const statuscode = {
        0: "Draft / Disabled",
        1: "Activated",
        2: "Deactive" 
    }

    useEffect(() => {
        if (!fetchedFlows) {
            setLoading(true)
            execute("GET", protectedResources.Dynamics365.endpoint + "/workflows?$filter=category eq 5&$select=category, name, description, statecode, workflowidunique").then(response => {
                if (response["value"] && Array.isArray(response["value"])) {
                    let responseVal = response["value"]
                    setAllFlows(response["value"])
  
                    let i = 0
  
                    responseVal.forEach(obj => {
                      obj.id = i
                      i++

                      obj.statecode_str = statuscode[obj.statecode]
                    })
                    
                    setRows(responseVal)
                    setLoading(false)
                    setfetchedFlows(true)
                }
            })
        }
    }, [execute, rows])

    const handleSelectFlow = (params) => {
        setSelectedFlow(params.row.workflowidunique)
        setSelectedFlowName(params.row.name)
    }

    if (allFlows) {
        return (
            <div style={{display: "flex", height: "80%", padding: "7px"}}>
                <div style={{width: "50%"}}>
                    <Typography variant="h4">All Flows</Typography>
                    <DataGrid disableMultipleRowSelection={true} initialState={{sorting: {sortModel: [{field: "name", sort: "asc"}]}}} onCellDoubleClick={(params) => {handleSelectFlow(params)}} loading={loading} apiRef={gridApiRef} checkboxSelection={false} columns={columns} rows={rows}></DataGrid>
                </div>
                <div style={{width: "7px"}}></div>
                <div style={{width: "50%"}}>
                    <Stack sx={{height: "80%"}}>
                        <div style={{height:"40%"}}>
                            <Typography variant="h4">Flow runs {selectedFlowName}</Typography>
                            <FlowRuns key={selectedFlow} flowID={selectedFlow}></FlowRuns>
                        </div>
                        <div style={{height: "40px"}}></div>
                        <div style={{height: "75%"}}>
                            <Typography variant="h4">Recently failed Flows</Typography>
                            <RecentlyFailedFlows flows={allFlows}></RecentlyFailedFlows>
                        </div>
                    </Stack>
                </div>
            </div>
        );
    }
}

export const TableMetaDataAdmin = () => {
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState(new Array)
    const [fetchedMetadata, setfetchedMetadata] = useState(false)

    const [selectedFlow, setSelectedFlow] = useState(false)
    const [selectedFlowName, setSelectedFlowName] = useState("None")

    const gridApiRef = useGridApiRef()
    
    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });  

    function sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
    }

    const columns = [
        {field: "DisplayName_str", headerName: "Name", width: 400},
        {field: "LogicalName", headerName: "LogicalName", width: 400},
        {field: "IsAuditEnabled_str", headerName: "Auditing", width: 200},
    ]

    useEffect(() => {
        if (!fetchedMetadata) {
            setLoading(true)
            execute("GET", protectedResources.Dynamics365.endpoint + "/EntityDefinitions").then(response => {
                if (response["value"] && Array.isArray(response["value"])) {
                    let responseVal = response["value"]
  
                    let i = 0
  
                    responseVal.forEach(obj => {
                      obj.id = i
                      i++

                      if (obj.DisplayName && 
                        Array.isArray(obj.DisplayName.LocalizedLabels) && 
                        obj.DisplayName.LocalizedLabels.length > 0 && 
                        obj.DisplayName.LocalizedLabels[0] && 
                        obj.DisplayName.LocalizedLabels[0].Label) {
                        obj.DisplayName_str = obj.DisplayName.LocalizedLabels[0].Label
                      }
                      obj.IsAuditEnabled_str = obj.IsAuditEnabled.Value
                    })

                    setRows(responseVal)
                    setLoading(false)
                    setfetchedMetadata(true)
                }
            })
        }
    }, [execute, rows])

    const enableAuditing = async () => {
        let gotEnabled = []
        let errorEnabling = []
        const selectedItems = gridApiRef.current.getSelectedRows().values()
    
        for (const value of selectedItems) {
            let MetadataID = value.MetadataId
            let newData = { ...value }
            console.log(newData)
    
            // Change value for audits, setting to true
            newData.IsAuditEnabled.Value = true
    
            // Delete custom attributes for displaying on the website
            delete newData.DisplayName_str
            delete newData.IsAuditEnabled_str
            delete newData.id
            
            console.log("starting request for " + newData.LogicalName)
            try {
                let response = await execute("PUT", protectedResources.Dynamics365.endpoint + `EntityDefinitions(${MetadataID})`, newData)
                if (response.ok) {
                    gotEnabled.push({"Name": newData.LogicalName, "status": 200})
                } else {
                    errorEnabling.push({"Name": newData.LogicalName, "status": 400})
                }
            } catch (error) {
                errorEnabling.push({"Name": newData.LogicalName, "status": 500, "error": error.message})
            }
        }
    
        console.log(gotEnabled, errorEnabling)
    }

    return (
        <div style={{padding: "7px"}}>
            <div style={{display: "flex"}}>
                <Typography variant="h4">All Tables</Typography>
                <div style={{width: "7px"}}></div>
                <Button onClick={enableAuditing} variant="contained">Enable Auditing</Button>
            </div>
            <div style={{height: "80%"}}>
                <DataGrid disableMultipleRowSelection={true} initialState={{sorting: {sortModel: [{field: "name", sort: "asc"}]}}} loading={loading} apiRef={gridApiRef} checkboxSelection={true} columns={columns} rows={rows}></DataGrid>
            </div>
        </div>
    );
}