import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

import { loginRequest } from '../authConfig';
import { Paper, Typography, Button, Grid } from "@mui/material";

import { AnwesenheitsdetailsView } from "../components/AnwesenheitsdetailsView";
import { ToDoView } from "../components/MicrosoftToDoView";
import { UeberzeitenView } from "../components/UeberzeitenView";
import { FakturaGraph } from "../components/FakturaGraph";
import { ZeiteintraegeView } from "../components/ZeiteintraegeView";
import { AnwesenheitsView } from "../components/AnwesenheitView";
import { ZeiteintraegeGraph } from "../components/ZeiteintraegeNachKunde";

import { RefreshProvider } from "../utils/RefreshContext";

import '../styles/Home.module.css'

import { AnwesenheitsButtons } from "../components/AnwesenheitsButtons";
import { useState } from 'react';


/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
    const { instance } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest)
            .catch((error) => console.log(error));
    };

    const [refreshButtons, setrefreshButtons] = useState(false)
    const [refreshAnwesenheitsdetails, setrefreshAnwesenheitsdetails] = useState(false)
    const [refeshGraph, setrefeshGraph] = useState(false)
    const [refreshAnwesenheiten, setrefreshAnwesenheiten] = useState(false)

    const handleRefreshRequest = (toRefresh) => {
        for (let key in toRefresh) {
            let value = toRefresh[key];
          
            switch (value) {
              case "Buttons":
                setrefreshButtons(true);
                break;
              case "Anwesenheitsdetails":
                setrefreshAnwesenheitsdetails(true);
                break;
              case "Graph":
                setrefeshGraph(true);
                break;
              case "Anwesenheiten":
                setrefreshAnwesenheiten(true);
                break;
              default:
                break;
            }
        console.log("statechange")
        }
    }

    const handleRefreshComplete = (hasRefreshed) => {
        for (let key in hasRefreshed) {
            let value = hasRefreshed[key];
          
            switch (value) {
              case "Buttons":
                setrefreshButtons(false);
                break;
              case "Anwesenheitsdetails":
                setrefreshAnwesenheitsdetails(false);
                break;
              case "Graph":
                setrefeshGraph(false);
                break;
              case "Anwesenheiten":
                setrefreshAnwesenheiten(false);
                break;
              default:
                break;
            }
        console.log("statechange")
        }
    }

    return (
        <>
            <AuthenticatedTemplate>
                <Grid paddingTop={1} paddingLeft={1} paddingRight={1} container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper id="AnwesenheitsButtonsPaper" elevation={3}>
                            <AnwesenheitsButtons refreshContext={refreshButtons} refreshRequestor={handleRefreshRequest} refreshCompleted={handleRefreshComplete}></AnwesenheitsButtons>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper id="ToDoListPaper" elevation={3}>
                            <ToDoView></ToDoView>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper id="UeberzeitenPaper" elevation={3}>
                            <UeberzeitenView></UeberzeitenView>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Paper id="AnwesenheitsdetailsPaper" elevation={3}>
                            <AnwesenheitsdetailsView refreshContext={refreshAnwesenheitsdetails} refreshRequestor={handleRefreshRequest} refreshCompleted={handleRefreshComplete}></AnwesenheitsdetailsView>
                        </Paper>
                    </Grid>
                    <Grid item xs={0} sm={0} md={9}>                        
                        <Paper id="ZeiteintraegeGraphPaper" elevation={3}>
                            <FakturaGraph refreshContext={refeshGraph} refreshRequestor={handleRefreshRequest} refreshCompleted={handleRefreshComplete}></FakturaGraph>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper id="ZeiteintraegeViewPaper" elevation={3}>
                            <ZeiteintraegeView></ZeiteintraegeView>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper id="ZeiteintraegeKundeGraphPaper" elevation={3}>
                            <ZeiteintraegeGraph></ZeiteintraegeGraph>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Paper id="AnwesenheitsViewPaper" elevation={3}>
                            <AnwesenheitsView refreshContext={refreshAnwesenheiten} refreshRequestor={handleRefreshRequest} refreshCompleted={handleRefreshComplete}></AnwesenheitsView>
                        </Paper>
                    </Grid>
                </Grid>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Grid paddingTop={1} paddingLeft={1} paddingRight={1} container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <img src="https://nms.de/wp-content/uploads/NMS-NewMediaService-Logo-weiss.svg" alt="NMS LOGO" style={{width: "350px", height: "350px", display: "block", margin: "auto"}} />
                    </Grid>
                    <Grid item xs={0} sm={0} md={12}><div style={{height: "10%"}}></div></Grid>
                    <Grid item xs={12} sm={12} md={2}></Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Paper id="KundenoptionsPaper">
                                    <div id="KundenOptions">
                                        <div id="KundenAzureLogin">
                                            <Typography variant="h5">Kunden LogIn</Typography>
                                            <div style={{width: "7px"}}></div>
                                            <Button variant='contained' onClick={handleLoginRedirect}>LogIn</Button>
                                        </div>
                                        <div id="KundenEinladungscode">
                                            <Typography variant="p"> - oder -</Typography>
                                            <Typography variant="h5">Code eingeben</Typography>
                                            <input className="CodeInput" type="text"/>
                                            <Button variant='contained' id="KundenLogIn">Bestätigen</Button>
                                        </div>
                                    </div>
                            </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}></Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Paper id="MitarbeiterLoginPaper">
                                <div id="MitarbeiterLogin">
                                    <Typography variant="h5">Mitarbeiter LogIn</Typography>
                                    <div style={{width: "7px"}}></div>
                                    <Button variant='contained' onClick={handleLoginRedirect}>LogIn</Button>
                                </div>
                            </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}></Grid>
                </Grid>
            </UnauthenticatedTemplate>
        </>
    );
}
