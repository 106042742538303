import { Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider, createTheme } from "@mui/material";
import ReactErrorBoundary from "./components/RuntimeErrorHandler";
import React from "react";
import {ContextProvider} from "./components/ContextProvider"

import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { NeuerZeiteintrag } from "./pages/ZeiteintragErstellen";
import { MeineAnwesenheit, DailySummaries, Anwesenheitsdetails } from "./pages/Anwesenheit";
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from 'prop-types';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { MeineAbwesenheiten, MeineVertretungen } from "./pages/Abwesenheit";
import { MeineZeiteinträge } from "./pages/Zeiteinträge";
import { AdminPowerAutomate, TableMetaDataAdmin } from "./pages/admin";

import "./styles/App.css";

const darkTheme = createTheme({
    palette: {
        primary: {main: '#0e72a8'},
        mode: 'dark',
    },
});
  

const Pages = () => {
    return (
        <Routes>
            <Route path="/Zeiteintrag/:id" element={<NeuerZeiteintrag></NeuerZeiteintrag>}></Route>
            <Route path="/Zeiteintrag" element={<NeuerZeiteintrag></NeuerZeiteintrag>}></Route>
            <Route path="/Anwesenheit" element={<MeineAnwesenheit></MeineAnwesenheit>}></Route>
            <Route path="/Anwesenheit/:id" element={<DailySummaries></DailySummaries>}></Route>
            <Route path="/Anwesenheitsdetails/:id" element={<Anwesenheitsdetails></Anwesenheitsdetails>}></Route>
            <Route path="/Abwesenheit" element={<MeineAbwesenheiten></MeineAbwesenheiten>}></Route>
            <Route path="/Vertretung" element={<MeineVertretungen></MeineVertretungen>}></Route>
            <Route path="/Meine Zeiteinträge" element={<MeineZeiteinträge></MeineZeiteinträge>}></Route>
            <Route path="/admin/PowerAutomate" element={<AdminPowerAutomate></AdminPowerAutomate>} />
            <Route path="/admin/TableMetadata" element={<TableMetaDataAdmin></TableMetaDataAdmin>} />
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

const App = ({ instance }) => {
    App.propTypes = {
        instance: PropTypes.PublicClientApplication
    }
    return (
        <MsalProvider instance={instance}>
            <AuthenticatedTemplate>
                <ReactErrorBoundary>
                    <ThemeProvider theme={darkTheme}>
                        <CssBaseline></CssBaseline>
                            <ContextProvider>
                                <PageLayout>
                                    <Pages />
                                </PageLayout>
                            </ContextProvider>
                    </ThemeProvider>
                </ReactErrorBoundary>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <ReactErrorBoundary>
                    <ThemeProvider theme={darkTheme}>
                        <CssBaseline></CssBaseline>
                            <PageLayout>
                                <Pages />
                            </PageLayout>
                    </ThemeProvider>
                </ReactErrorBoundary>
            </UnauthenticatedTemplate>
        </MsalProvider>

    );
};

export default App;
