import { useState, useEffect, useContext } from "react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { AssembleQuery, Restquery } from "../queries/AssembleQuery";
import { IconButton, Skeleton, Typography } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../components/ContextProvider';
import { getFirstDayOfWeek } from "../utils/DateUtils";

function convertMinutesToHoursAndMinutes(minutes) {
    if (typeof minutes !== 'number' || minutes < 0) {
        return { error: 'Invalid input. Please provide a non-negative number of minutes.' };
    }

    const hours = Math.floor(minutes / 60)
    const adjustedHours = String(hours).padStart(2, '0');
    const remainingMinutes = String(minutes % 60).padStart(2, '0');

    return { hours: adjustedHours, minutes: remainingMinutes };
}

function convertTimeFormats(timeString) {
    const date = new Date(timeString);

    // Dot format: day.month.year hours:minutes:seconds
    const dotFormat = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

    return dotFormat;
}


export const ZeiteintraegeView = () =>  {
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const navigate = useNavigate()

    const handleCreateZeiteintrag = () => {

    }
    let weekstart = getFirstDayOfWeek()
    const [TimeItems, setTimeItems] = useState()

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const [Zeiteintraege, setZeiteintraege] = useState(null);

    const handleEditZeiteintrag = (id) => {
        // Edit on Page
        //navigate("/Zeiteintrag/" + id)

        window.open('https://nms24.crm4.dynamics.com/main.aspx?appid=b6b166aa-bda1-eb11-b1ac-000d3a2ba37a&pagetype=entityrecord&etn=catbase_timeitem&id=' + id, '_blank');
    }

    useEffect(() => {
        if (!Zeiteintraege) {
            var requestParameters = new Restquery()
            requestParameters.EntityName = "catbase_timeitems"
            requestParameters.FilterCriteria = "catbase_starttime ge '" + weekstart + "'" + " and _owninguser_value eq " + SystemuserGUID + "&$orderby=catbase_starttime desc"
            requestParameters.ExpandNames = "catbase_customerid"
        
            var request = AssembleQuery(requestParameters);

            execute("GET", protectedResources.Dynamics365.endpoint + request).then((response) => {
                setZeiteintraege(response["value"])
                if (response["value"]) {
                    setTimeItems(response["value"].map(entry => {
                        var dateOfEntry = convertTimeFormats(entry.catbase_starttime);
                        var Duration = convertMinutesToHoursAndMinutes(entry.catbase_durationminutes)
                        return (
                        <tr key={entry.catbase_timeitemid} className="TimeItem">
                            <td className="Datum">
                            {dateOfEntry}
                            </td>
                            <td className="Dauer">
                            {Duration.hours + ":" + Duration.minutes}
                            </td>
                            <td className="Beschreibung">
                            {entry.catbase_description}
                            </td>
                            <td className="Kunde">
                            {entry.catbase_customerid.name}
                            </td>
                            <td className="OpenInZV">
                                <IconButton className="OpenInZV" onClick={() => {handleEditZeiteintrag(entry.catbase_timeitemid)}}><OpenInNewIcon></OpenInNewIcon></IconButton>
                            </td>
                        </tr>
                        )
                    }));
                }
            });
        }
    }, [execute, Zeiteintraege])

    if (TimeItems) {
        return (
            <div id="ZeiteintraegeView">
                <Typography variant="h5">Zeiteinträge dieser Woche</Typography>
                <table id="TimeEntries">
                    <thead>
                        <tr className="THEADZTW">
                            <td className="Datum">
                            Datum
                            </td>
                            <td className="Dauer">
                            Dauer
                            </td>
                            <td className="Beschreibung">
                            Beschreibung
                            </td>
                            <td className="Kunde">
                            Kunde
                            </td>
                            <td className="AddNewZV">
                            <IconButton href="./Zeiteintrag" onClick={handleCreateZeiteintrag} className="AddNewZV"><AddIcon></AddIcon></IconButton>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {TimeItems}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div id="ZeiteintraegeView">
                <Typography variant="h5">Zeiteinträge dieser Woche</Typography>
                <table id="TimeEntries">
                    <thead>
                        <tr className="THEADZTW">
                            <td className="Datum">
                            Datum
                            </td>
                            <td className="Dauer">
                            Dauer
                            </td>
                            <td className="Beschreibung">
                            Beschreibung
                            </td>
                            <td className="Kunde">
                            Kunde
                            </td>
                            <td className="AddNewZV">
                            <IconButton href="./Zeiteintrag" onClick={handleCreateZeiteintrag} className="AddNewZV"><AddIcon></AddIcon></IconButton>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="Datum">
                                <Skeleton sx={{width: "80%"}}></Skeleton>
                            </td>
                            <td className="Dauer">
                                <Skeleton sx={{width: "80%"}}></Skeleton>
                            </td>
                            <td className="Beschreibung">
                                <Skeleton sx={{width: "80%"}}></Skeleton>
                            </td>
                            <td className="Kunde">
                                <Skeleton sx={{width: "80%"}}></Skeleton>
                            </td>
                            <td className="OpenInZV">
                                <IconButton className="OpenInZV" onClick={() => {handleEditZeiteintrag(entry.catbase_timeitemid)}}><OpenInNewIcon></OpenInNewIcon></IconButton>
                            </td>
                        </tr>
                        </tbody>
                </table>
            </div>
        );
    }
}