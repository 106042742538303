import { useState, useRef } from 'react';
import { Modal, Typography, Paper, TextField, Button, Select, MenuItem } from '@mui/material';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';
import { AlertComponent } from './ErrorModal';
import { useDisable } from '../hooks/useDisable';

export default function FeedbackCollector(props) {
    const [open, setOpen] = useState(true)
    const [Alert, setAlert] = useState(null)
    const [states, changeStates] = useDisable({"feedbackButton": false})

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const handleClose = () => {
        props.closeCallback()
        setOpen(false)
    }

    const closeCallback = () => {
        setAlert(null)
    }

    const handleAbsenden = () => {
        changeStates("feedbackButton", true)
        setAlert(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Feedback wird übermittelt..."} status={"info"}></AlertComponent>)
        const payloadData = {
            "nms_feedbacktext": nms_FeedbackText.current.value,
            "nms_gabeseinefehlermeldung": FehlermeldungSelected,
            "nms_weistderfehlerentstanden": nms_fehlerentstanden.current.value,
            "nms_woistderfehler": nms_Woistderfehler.current.value,
        }

        execute("POST", protectedResources.Dynamics365.endpoint + "nms_feedbacks", payloadData).then((response) => {
            if (response.ok) {
                setAlert(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Feedback wurde übermittelt"} status={"success"}></AlertComponent>)
                changeStates("feedbackButton", false)
            } else {
                setAlert(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Feedback konnte nicht übermittelt werden"} status={"error"}></AlertComponent>)
                changeStates("feedbackButton", false)
            }
        });
    }

    const nms_fehlerentstanden = useRef(null)
    const nms_FeedbackText = useRef(null)
    const nms_Woistderfehler = useRef(null)
    const [FehlermeldungSelected, setFehlermeldungSelected] = useState(false)

    return (
        <>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{justifyContent: "center", alignItems: "center"}}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "auto"}}>
                    <Paper elevation={3} sx={{width: "fit-content", paddingLeft: "15px", paddingRight: "15px", paddingBottom: "15px"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Typography id="modal-modal-title" variant="h6" component="h5">Feedback</Typography>
                            <div style={{width: "15px"}}></div>
                            <Button disabled={states.feedbackButton} sx={{height: "50%"}} onClick={handleAbsenden} variant='contained'>Absenden</Button>
                        </div>
                        
                        <div style={{height: "15px"}}></div>
                        <Typography variant="p" component="p">Wo ist der Fehler aufgetreten / Zu welcher Seite willst du Feedback geben</Typography>
                        <TextField inputProps={{maxLength: 850}} id="nms_remarkintern" inputRef={nms_Woistderfehler} multiline fullWidth rows={1} defaultValue="" variant="standard"/>

                        <div style={{height: "15px"}}></div>
                        <Typography variant="p" component="p">Wie ist der Fehler Entstanden</Typography>
                        <TextField inputProps={{maxLength: 850}} id="nms_remarkintern" inputRef={nms_fehlerentstanden} multiline fullWidth rows={1} defaultValue="" variant="standard"/>

                        <div style={{height: "15px"}}></div>
                        <Typography variant="p" component="p">Gab es eine Fehlermeldung</Typography>
                        <Select style={{height: "53px"}} variant='standard' fullWidth onChange={(event) => {setFehlermeldungSelected(event.target.value)}} value={FehlermeldungSelected}>
                            <MenuItem value={true}>Ja</MenuItem>  
                            <MenuItem value={false}>Nein</MenuItem>
                        </Select>

                        <div style={{height: "15px"}}></div>
                        <Typography variant="p" component="p">Feedback / Fehlermeldung</Typography>
                        <TextField inputProps={{maxLength: 850}} id="nms_remarkintern" inputRef={nms_FeedbackText} multiline fullWidth rows={1} defaultValue="" variant="standard"/>
                    </Paper>
                </div>
            </Modal>
            {Alert}
        </>
    )
}