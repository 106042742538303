import { ErrorBoundary } from "react-error-boundary";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const ErrorDisplay = (props) => {
    const { instance } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    let currentEmail

    if (activeAccount) {
        currentEmail = activeAccount.username
    }

    const [status, setStatus] = useState(null)

    useEffect(() => {
        async function sendData() {
            if (status === null) {
                const payloadData = {
                    "nms_weistderfehlerentstanden": String(props.error.stack),
                    "nms_woistderfehler": String(currentEmail)
                }
    
                console.log("request triggered")
            
                const response = await fetch("https://prod-230.westeurope.logic.azure.com:443/workflows/6bfcc982f2d8437a9505c56cd676cbbb/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=G59qTEKBPfBmbfOmrtYcOTLp-uUPxeuinSeE9e8H52c", {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json', // Specify the content type
                    },
                    body: JSON.stringify(payloadData), // Convert the data to JSON format
                  })

                setStatus(response.ok)
            }
        }

        sendData()

    }, [status])


    if (status) {
        return (
            <div>
                <h1>Runtime error, Fehler wurde bereits übermittelt</h1>
                <p>{String(props.error)}</p>
                <p>{String(props.error.stack)}</p>
                <Button variant="contained" onClick={props.resetErrorBoundary}>Erneut versuchen</Button>
            </div>
        );
    } else {
        return (
            <div>
                <h1>Leider gab es ein Problem, bitte warten Sie einen Moment...</h1>
                <p>{String(props.error)}</p>
                <p>{String(props.error.stack)}</p>
            </div>
        )
    }
}

export default function ReactErrorBoundary(props) {
    return (
        <ErrorBoundary
            FallbackComponent={(fallbackProps) => <ErrorDisplay {...fallbackProps} />}
        >
            {props.children}
        </ErrorBoundary>
    );
}