import React, { useState } from "react";
import { Alert, Snackbar} from "@mui/material"
import PropTypes from 'prop-types';

export const AlertComponent = (props) => {
    const [openState, setOpenState] = useState(props.showMo)

    AlertComponent.propTypes = {
        closeCallback: PropTypes.func,
        showMo: PropTypes.bool,
        status: PropTypes.string,
        message: PropTypes.string
    }

    const handleClose = () => {
        props.closeCallback()
        setOpenState(false)
    }

    return (
        <Snackbar open={openState} message={props.message} color={props.status} autoHideDuration={2500} onClose={handleClose}>
            <Alert variant="filled" severity={props.status} onClose={handleClose} sx={{width: "fit-content"}}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}