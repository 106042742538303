import {Button} from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { IconButton } from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const StateHoverIcon = (props) => {
    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.ToDo.scope]
    });

    const handleComplete = () => {
        let id = props.keyInput
        let list = props.list

        isSetToComplete = true
        //send request to complete here
        let payload = {
            "status": "completed"
        }

        execute("PATCH", protectedResources.ToDo.endpoint + "/me/todo/lists/" + list + "/tasks/" + id, payload).then((response) => {
            props.completeCallback()
        })
    }

    var isSetToComplete = false

    const [isHovered, setIsHovered] = useState(false);

    // Function to handle mouse enter event
    const handleMouseEnter = () => {
        if (isSetToComplete != true) {
            setIsHovered(true);
        }
    };
  
    // Function to handle mouse leave event
    const handleMouseLeave = () => {
        if (isSetToComplete != true) {
            setIsHovered(false);
        }
    };

    return (
        <IconButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleComplete} size="small">
            {isHovered ? <CheckCircleOutlineIcon></CheckCircleOutlineIcon> : <RadioButtonUncheckedIcon/>}
        </IconButton>
    );
}