import React, { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { UserContext } from "../components/ContextProvider"
import { Typography, Button, IconButton, Box } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid"
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';
import { styled } from '@mui/material/styles';
import { AlertComponent } from '../components/ErrorModal';
import moment from "moment";
import { getFirstDayOfMonthZT } from "../utils/DateUtils"
import { Scheduler } from "@aldabil/react-scheduler";
import { de } from 'date-fns/locale'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import '../styles/Anwesenheit.module.css'

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));
  

function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Keine Einträge vorhanden, oder es ist ein Fehler beim laden aufgetreten</Box>
      </StyledGridOverlay>
    );
}

export const MeineZeiteinträge = () => {
    const navigate = useNavigate()
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const currentMonth = getFirstDayOfMonthZT()
    const gridApiRef = useGridApiRef()

    const [rows, setrows] = useState(new Array)
    const [events, setEvents] = useState(new Array)
    const [fetched, setFetched] = useState(false)
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState("Table")

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const columns = [
      { field: "catbase_starttime_str", headerName: "Start", width: 150, editable: true },
      { field: "catbase_endtime_str", headerName: "Ende", width: 150, editable: true },
      { field: "catbase_name", headerName: "Bezeichnung", width: 150, editable: true },
      { field: "catbase_customerid_str", headerName: "Kunde", width: 150, editable: true },
      { field: "catbase_durationminutes", headerName: "Dauer (Minuten)", width: 150, editable: true },
      { field: "catbase_billabledurationminutes", headerName: "Dauer Faktura (Minuten)", width: 150, editable: true },
      { field: "catbase_isbillable_str", headerName: "Fakturierbar", width: 150, editable: true },
      { field: "catbase_breakduration", headerName: "Pause", width: 150, editable: true },
      { field: "nms_stundenlohn", headerName: "Stundensatz", width: 150, editable: true },
    ];

    useEffect(() => {
        if (fetched === false) {
            setLoading(true)
            execute("GET", protectedResources.Dynamics365.endpoint + "/catbase_timeitems?$filter=_owninguser_value eq " + SystemuserGUID + " and catbase_date ge '" + currentMonth + "'" + "&$expand=catbase_customerid($select=name)&$orderby=catbase_date desc").then(response => {
                if (response && response["value"] && Array.isArray(response["value"])) {
                    let responseVal = response["value"]
                    let eventsTemp = new Array
                    
                    responseVal.forEach(obj => {
                        obj.catbase_starttime_str = moment(obj.catbase_starttime).format("DD.MM.YYYY HH:mm")
                        obj.catbase_endtime_str = moment(obj.catbase_endtime).format("DD.MM.YYYY HH:mm")
                        obj.catbase_customerid_str = obj.catbase_customerid.name
                        if (obj.catbase_isbillable == true) {
                            obj.catbase_isbillable_str = "Laut Einstellung"
                        } else {
                            obj.catbase_isbillable_str = "Nein"
                        }
                    })

                    responseVal.forEach(obj => {
                        eventsTemp.push({event_id: obj.catbase_timeitemid, title: obj.catbase_name, start: new Date(obj.catbase_starttime), end: new Date(obj.catbase_endtime)})
                    })

                    setEvents(eventsTemp)
                    setrows(responseVal)
                    setLoading(false)
                    setFetched(true)
                }
            })
        }
    }, [execute, rows])

    const handleOpenSelected = () => {
        const selectedItems= gridApiRef.current.getSelectedRows()
        selectedItems.forEach((value, key) => {
            window.open("https://nms24.crm4.dynamics.com/main.aspx?appid=b6b166aa-bda1-eb11-b1ac-000d3a2ba37a&pagetype=entityrecord&etn=catbase_timeitem&id=" + key, "_blank")
        });
    }

    const handleOpenSingle = (params, fromEventEdit) => {
        if (fromEventEdit === true) {
            window.open("https://nms24.crm4.dynamics.com/main.aspx?appid=b6b166aa-bda1-eb11-b1ac-000d3a2ba37a&pagetype=entityrecord&etn=catbase_timeitem&id=" + params.event_id, "_blank")
        } else {
            window.open("https://nms24.crm4.dynamics.com/main.aspx?appid=b6b166aa-bda1-eb11-b1ac-000d3a2ba37a&pagetype=entityrecord&etn=catbase_timeitem&id=" + params.row.catbase_timeitemid, "_blank")
        }
    }

    const handleChangeView = (View) => {
        console.log("New View " + View)
        setView(View)
    }

    if (view == "Table") {
        return (
            <div style={{height: "80%", width: "100%", padding: "7px"}}>
                <div style={{display: "flex"}}>
                    <Typography variant="h4">Meine Zeiteinträge, dieser Monat</Typography>
                    <div style={{width: "7px"}}></div>
                    <Button variant="contained" onClick={handleOpenSelected}>Ausgewählte öffnen</Button>
                    <div style={{width: "7px"}}></div>
                    <Button variant="contained" onClick={() => {handleChangeView("Calender")}}>Ansicht ändern <CalendarMonthIcon sx={{paddingLeft: "5px"}}></CalendarMonthIcon></Button>
                    <div style={{width: "7px"}}></div>
                    <IconButton href="./Zeiteintrag" className="AddNewZV"><AddIcon></AddIcon></IconButton>
                </div>
                <div style={{height: "7px"}}></div>
                <div style={{height: "7px"}}></div>
                <DataGrid disableColumnResize={false} loading={loading} initialState={{sorting: {sortModel: [{field: "catbase_starttime_str", sort: "desc"}]}}} getRowId={(row) => { return row.catbase_timeitemid }} slots={{ noRowsOverlay: CustomNoRowsOverlay }} apiRef={gridApiRef} onCellDoubleClick={(params) => {handleOpenSingle(params)}} checkboxSelection={true} columns={columns} rows={rows}></DataGrid>
            </div>
        )
    } else if (view == "Calender") {
        return (
            <div style={{height: "80%", width: "100%", padding: "7px"}}>
                <div style={{display: "flex"}}>
                    <Typography variant="h4">Meine Zeiteinträge, dieser Monat</Typography>
                    <div style={{width: "7px"}}></div>
                    <Button variant="contained" onClick={handleOpenSelected}>Ausgewählte öffnen</Button>
                    <div style={{width: "7px"}}></div>
                    <Button variant="contained" onClick={() => {handleChangeView("Table")}}>Ansicht ändern <TableRowsIcon sx={{paddingLeft: "5px"}}></TableRowsIcon></Button>
                    <div style={{width: "7px"}}></div>
                    <IconButton href="./Zeiteintrag" className="AddNewZV"><AddIcon></AddIcon></IconButton>
                </div>
                <div style={{height: "7px"}}></div>
                <div style={{height: "7px"}}></div>
                <Scheduler stickyNavigation={true} editable={false} draggable={false} deletable={false} locale={de} week={{startHour: 6, endHour: 21, step: 60}} day={{startHour: 6, endHour: 21, step: 60}} hourFormat="24" view="week" events={events}></Scheduler>
            </div>
        )
    }
}