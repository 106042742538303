import React, { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { UserContext } from "../components/ContextProvider"
import { Typography, Button, IconButton, Box } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid"
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';
import { styled } from '@mui/material/styles';
import { getFirstDayOfYear } from "../utils/DateUtils"

import moment from "moment";

import '../styles/Anwesenheit.module.css'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const absenanceType = {
  693100000: "Urlaub",
  693100001: "Krankheit",
  693100002: "Überstundenausgleich",
  693100005: "Sonderurlaub",
  693100006: "Unbezahlter Urlaub",
  693100004: "Kind Krank",
  693100003: "Arbeitsunfall",
  693100007: "Elternzeit",
  693100008: "Mutterschutz",
  693100009: "Unentschuldigtes Fehlen",
  693100010: "Öffentlicher Feiertag",
  693100011: "Sabbaturlaub",
  693100012: "Überzeitausgleich"
}

const statuscode = {
  1: "Entwurf",
  693100002: "In Bearbeitung",
  2: "Genehmigt",
  693100000: "Abgelehnt",
  693100003: "Abgebrochen"
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  

function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Keine Einträge vorhanden, oder es ist ein Fehler beim laden aufgetreten</Box>
      </StyledGridOverlay>
    );
  }

export const MeineAbwesenheiten = () => {
  const navigate = useNavigate()
  const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
  const gridApiRef = useGridApiRef()

  const [rows, setrows] = useState(new Array)
  const [fetched, setFetched] = useState(false)
  const [loading, setLoading] = useState(false)

  const { error, execute } = useFetchWithMsal({
      scopes: [protectedResources.Dynamics365.scope]
  });

  const columns = [
    {field: "xrm1_leave_from_str", headerName: "Von", width: 150},
    {field: "xrm1_leave_until_str", headerName: "Bis", width: 150},
    {field: "xrm1_absence_type_str", headerName: "Art", width: 150},
    {field: "xrm1_days", headerName: "Tage", width: 150},
    {field: "xrm1_leave_request_substitute_user_id_str", headerName: "Vertreter", width: 150},
    {field: "statuscode_str", headerName: "Status", width: 150},
    {field: "xrm1_comment", headerName: "Kommentar", width: 150},
  ]

    useEffect(() => {
      if (!fetched) {
          setLoading(true)
          execute("GET", protectedResources.Dynamics365.endpoint + "/xrm1_leave_requests?$filter=_xrm1_leave_request_employee_id_value eq " + MitarbeiterGUID + " and xrm1_leave_until ge " + getFirstDayOfYear() + "&$expand=xrm1_leave_request_substitute_user_id($select=fullname)&$orderby=xrm1_leave_until desc").then(response => {
              if (response && response["value"] && Array.isArray(response["value"])) {
                  let responseVal = response["value"]

                  let i = 0

                  responseVal.forEach(obj => {
                    obj.id = i
                    i++

                    obj.xrm1_leave_from_str = moment(obj.xrm1_leave_from).format("DD.MM.YYYY")
                    obj.xrm1_leave_until_str = moment(obj.xrm1_leave_until).format("DD.MM.YYYY")
                    obj.xrm1_absence_type_str = absenanceType[obj.xrm1_absence_type]
                    obj.statuscode_str = statuscode[obj.statuscode]

                    if (obj.xrm1_leave_request_substitute_user_id) {
                      obj.xrm1_leave_request_substitute_user_id_str = obj.xrm1_leave_request_substitute_user_id.fullname
                    } else {
                      obj.xrm1_leave_request_substitute_user_id_str = "Kein Vertreter angegeben"
                    }
                  })
                  
                  setrows(responseVal)
                  setLoading(false)
                  setFetched(true)
              }
          })
      }
  }, [execute, rows])

  const handleOpenSelected = () => {
    const selectedItems= gridApiRef.current.getSelectedRows()
    selectedItems.forEach((value, key) => {
        window.open("https://nms24.crm4.dynamics.com/main.aspx?appid=b6b166aa-bda1-eb11-b1ac-000d3a2ba37a&pagetype=entityrecord&etn=xrm1_leave_request&id=" + value.xrm1_leave_requestid, "_blank")
    });
  }

  return (
    <div className="MeineAnwesenheitMain">
        <div style={{display: "flex"}}>
            <Typography variant="h4">Mein Urlaub dieses Jahr</Typography>
            <div style={{width: "7px"}}></div>
            <Button variant="contained" onClick={handleOpenSelected}>Ausgewählte öffnen</Button>
            <div style={{width: "7px"}}></div>
            <Button variant="contained" onClick={() => {window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=yrb1ukjMiEuYwPeJVGQL7SgEN6pbIbJHmQcAY3T55jFUMjlPWlpETkFJTDZSTEQ4M0pBN1ROSVdDRC4u", "_blank")}}>Krankheit melden</Button>
        </div>
        <div style={{height: "7px"}}></div>
        <div style={{height: "7px"}}></div>
        <DataGrid initialState={{filter: {filterModel:{ items:[{field: "xrm1_absence_type_str", operator: "equals", value: "Urlaub"}]}}}} loading={loading} slots={{ noRowsOverlay: CustomNoRowsOverlay }} apiRef={gridApiRef} checkboxSelection={true} columns={columns} rows={rows}></DataGrid>
    </div>
  );
}

export const MeineVertretungen = () => {
  const navigate = useNavigate()
  const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
  const gridApiRef = useGridApiRef()

  const [rows, setrows] = useState(new Array)
  const [fetched, setFetched] = useState(false)
  const [loading, setLoading] = useState(false)

  const { error, execute } = useFetchWithMsal({
      scopes: [protectedResources.Dynamics365.scope]
  });

  const columns = [
    {field: "xrm1_leave_from_str", headerName: "Von", width: 150},
    {field: "xrm1_leave_until_str", headerName: "Bis", width: 150},
    {field: "xrm1_absence_type_str", headerName: "Art", width: 150},
    {field: "xrm1_days", headerName: "Tage", width: 150},
    {field: "xrm1_leave_request_substitute_user_id_str", headerName: "Vertretung für", width: 150},
    {field: "statuscode_str", headerName: "Status", width: 150},
    {field: "xrm1_comment", headerName: "Kommentar", width: 150},
  ]

    useEffect(() => {
      if (!fetched) {
          setLoading(true)
          execute("GET", protectedResources.Dynamics365.endpoint + "/xrm1_leave_requests?$filter=_xrm1_leave_request_substitute_user_id_value eq " + SystemuserGUID + " and xrm1_leave_until ge " + getFirstDayOfYear() + "&$expand=owninguser($select=fullname)&$orderby=xrm1_leave_until desc").then(response => {
              if (response && response["value"] && Array.isArray(response["value"])) {
                  let responseVal = response["value"]

                  let i = 0

                  responseVal.forEach(obj => {
                    obj.id = i
                    i++

                    obj.xrm1_leave_from_str = moment(obj.xrm1_leave_from).format("DD.MM.YYYY")
                    obj.xrm1_leave_until_str = moment(obj.xrm1_leave_until).format("DD.MM.YYYY")
                    obj.xrm1_absence_type_str = absenanceType[obj.xrm1_absence_type]
                    obj.statuscode_str = statuscode[obj.statuscode]

                    if (obj.owninguser) {
                      obj.xrm1_leave_request_substitute_user_id_str = obj.owninguser.fullname
                    } else {
                      obj.xrm1_leave_request_substitute_user_id_str = "Kein Mitarbeiter angegeben"
                    }
                  })

                  setrows(responseVal)
                  setLoading(false)
                  setFetched(true)
              }
          })
      }
  }, [execute, rows])

  const handleOpenSelected = () => {
    const selectedItems= gridApiRef.current.getSelectedRows()
    selectedItems.forEach((value, key) => {
        window.open("https://nms24.crm4.dynamics.com/main.aspx?appid=b6b166aa-bda1-eb11-b1ac-000d3a2ba37a&pagetype=entityrecord&etn=xrm1_leave_request&id=" + value.xrm1_leave_requestid, "_blank")
    });
  }

  return (
    <div className="MeineAnwesenheitMain">
        <div style={{display: "flex"}}>
            <Typography variant="h4">Meine Vertretungen dieses Jahr</Typography>
            <div style={{width: "7px"}}></div>
            <Button variant="contained" onClick={handleOpenSelected}>Ausgewählte öffnen</Button>
        </div>
        <div style={{height: "7px"}}></div>
        <div style={{height: "7px"}}></div>
        <DataGrid loading={loading} slots={{ noRowsOverlay: CustomNoRowsOverlay }} apiRef={gridApiRef} checkboxSelection={true} columns={columns} rows={rows}></DataGrid>
    </div>
  );
}