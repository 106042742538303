import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import React, { useState, useEffect, useContext } from "react";
import { Restquery } from "../queries/AssembleQuery";
import { LineChart } from "@mui/x-charts";
import { IconButton, MenuItem, Typography, Select, LinearProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { getFirstDayOfMonth, getFirstDayOfMonthZT, getLastDayOfMonthFormatted, getFirstDayOfLastMonthZT } from "../utils/DateUtils";
import { UserContext } from '../components/ContextProvider';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

function getCurrentMonthDays() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
        const formattedDate = `${day < 10 ? '0' : ''}${day}`;
        daysArray.push(formattedDate);
    }
    return daysArray;
}

function getLastMonthDays() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() - 1;
    if (month < 0) {
        month = 11; // December (index 11) if current month is January
        year--; // Year decremented if current month is January
    }
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
        const formattedDate = `${day < 10 ? '0' : ''}${day}`;
        daysArray.push(formattedDate);
    }
    return daysArray;
}

// Function to get the current month and year
function getCurrentMonthAndYear() {
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1; // Month is 0-indexed
    let currentYear = currentDate.getFullYear();
    return { currentMonth, currentYear };
}

// Function to get the previous month and year
function getPreviousMonthAndYear() {
    let currentDate = new Date();
    let previousMonth = currentDate.getMonth(); // Month is 0-indexed
    let previousYear = currentDate.getFullYear();
    if (previousMonth === 0) { // January case
        previousMonth = 12;
        previousYear -= 1;
    }
    return { previousMonth, previousYear };
}

// Function to get the last day of a given month and year
function getLastDayOfMonth(month, year) {
    return new Date(year, month, 0).getDate(); // month is 1-indexed
}


export const FakturaGraph = () => {
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const { instance } = useMsal();

    let activeAccount;
    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    let monthStart = getFirstDayOfMonth()
    let monthEnde = getLastDayOfMonthFormatted()
    let monthStartZT = getFirstDayOfMonthZT()
    let lastMonthStart = getFirstDayOfLastMonthZT()

    const [selectedValue, setSelectedValue] = useState("diesen Monat");
    const [ArbeitszeitImMonat, setArbeitszeitImMonat] = useState(null)
    const [ZeiteintraegeImMonat, setZeiteintraegeImMonat] = useState(null)

    let labels

    if (selectedValue == "diesen Monat") {
        labels = getCurrentMonthDays()
    } else {
        labels = getLastMonthDays()
    }

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const handleChange = (event) => {
        setArbeitszeitImMonat(null)
        setZeiteintraegeImMonat(null)
        setSelectedValue(event.target.value);
    };

    let currentEmail = String(activeAccount.username)

    let requestParameterSystemuser = new Restquery()
    requestParameterSystemuser.EntityName = "systemusers"
    requestParameterSystemuser.FilterCriteria = "domainname eq '" + currentEmail + "'"

    useEffect(() => {
        if (!ZeiteintraegeImMonat) {
            let requestZT
            if (selectedValue == "diesen Monat") {
                requestZT = "catbase_timeitems?$filter=catbase_starttime gt " + monthStartZT + " and _owninguser_value eq " + SystemuserGUID + "&$select=catbase_durationhours&$orderby=catbase_starttime"
            } else {
                requestZT = "catbase_timeitems?$filter=catbase_starttime gt " + lastMonthStart + " and catbase_starttime lt " + monthStartZT + " and _owninguser_value eq " + SystemuserGUID + "&$select=catbase_durationhours&$orderby=catbase_starttime"
            }

            execute("GET", protectedResources.Dynamics365.endpoint + requestZT).then((response) => {
                if (response["value"]) {
                    const zeiteintraege = response["value"];
                    const aggregatedZeiteintraege = {};

                    // Aggregate durations based on date
                    zeiteintraege.forEach((Zeit) => {
                        const date = Zeit["catbase_starttime"].split("T")[0]; // Extracting date part only
                        const duration = Zeit["catbase_durationhours"];
                        if (aggregatedZeiteintraege[date]) {
                            aggregatedZeiteintraege[date] += duration;
                        } else {
                            aggregatedZeiteintraege[date] = duration;
                        }
                    });

                    // Iterate over dates of current month and fill missing dates with 0 durations
                    let currentDate, currentMonth, currentYear, lastDayOfMonth;

                    if (selectedValue == "diesen Monat") {
                        // Get current month and year
                        ({ currentMonth, currentYear } = getCurrentMonthAndYear());
                    
                        lastDayOfMonth = getLastDayOfMonth(currentMonth, currentYear);
                    } else {
                        // Get previous month and year
                        let { previousMonth, previousYear } = getPreviousMonthAndYear();
                        
                        currentMonth = previousMonth;
                        currentYear = previousYear;
                    
                        lastDayOfMonth = getLastDayOfMonth(currentMonth, currentYear);
                    }

                    for (let i = 1; i <= lastDayOfMonth; i++) {
                        const currentDateFormatted = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${i.toString().padStart(2, '0')}`;
                        if (!aggregatedZeiteintraege[currentDateFormatted]) {
                            aggregatedZeiteintraege[currentDateFormatted] = 0;
                        }
                    }

                    // Sort aggregated data by date
                    const sortedAggregatedArray = Object.entries(aggregatedZeiteintraege)
                        .sort((a, b) => new Date(a[0]) - new Date(b[0]))
                        .map(([date, duration]) => duration);

                    setZeiteintraegeImMonat(sortedAggregatedArray);
                }
            });
        }
        if (!ArbeitszeitImMonat && ZeiteintraegeImMonat) {
            let requestAB
            if (selectedValue == "diesen Monat") {
                requestAB = "xrm1_time_summary_dailies?$filter=xrm1_date ge " + monthStart + " and xrm1_date le " + monthEnde + " and _xrm1_time_summary_daily_employee_id_value eq " + MitarbeiterGUID + "&$select=xrm1_hours_attended&$orderby=xrm1_date"
            } else {
                requestAB = "xrm1_time_summary_dailies?$filter=xrm1_date ge " + lastMonthStart + " and xrm1_date lt " + monthStart + " and _xrm1_time_summary_daily_employee_id_value eq " + MitarbeiterGUID + "&$select=xrm1_hours_attended&$orderby=xrm1_date"
            }
            
            execute("GET", protectedResources.Dynamics365.endpoint + requestAB).then((response) => {
                if (response["value"]) {
                    const Arbeitszeit = response["value"].map((Zeit) => Zeit["xrm1_hours_attended"]);
                    setArbeitszeitImMonat((prevArbeitszeit) => {
                        if (prevArbeitszeit) {
                            return [...prevArbeitszeit];
                        } else {
                            return Arbeitszeit;
                        }

                    });

                }
            });
        }
    }, [execute, ZeiteintraegeImMonat, ArbeitszeitImMonat]);

    const calculateSum = (data) => data.reduce((acc, value) => acc + value, 0).toFixed(2);

    if (ArbeitszeitImMonat && ZeiteintraegeImMonat) {
        const sumZeiteintraege = calculateSum(ZeiteintraegeImMonat);
        const sumArbeitszeit = calculateSum(ArbeitszeitImMonat);
        return (
            <div id="ZeiteintraegeGraph">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ marginRight: "5px" }} variant="h5">Zeiteinträge und Anwesenheit</Typography>
                    <Select renderValue={(selected) => (<Typography padding={0} variant="h5">{selected}</Typography>)} variant="standard" value={selectedValue} onChange={handleChange} sx={{ '&::before': { borderBottom: 'none !important' }, '&::after': { borderBottom: 'none !important' }, '&:hover::before': { borderBottom: 'none !important' }, '&:hover::after': { borderBottom: 'none !important' }, '&:focus::before': { borderBottom: 'none !important' }, '&:focus::after': { borderBottom: 'none !important' } }}>
                        <MenuItem value="diesen Monat">Dieser Monat</MenuItem>
                        <MenuItem value="letzter Monat">Letzter Monat</MenuItem>
                    </Select>
                </div>
                <div style={{ width: "100%", height: "90%" }}>
                    <LineChart xAxis={[{ data: labels }]} series={[
                        {
                            id: 'Zeiteintraege',
                            label: `Zeiteinträge (Sum: ${sumZeiteintraege})`,
                            data: ZeiteintraegeImMonat
                        },
                        {
                            id: 'Arbeitszeit',
                            label: `Arbeitszeit (Sum: ${sumArbeitszeit})`,
                            data: ArbeitszeitImMonat
                        }
                    ]} margin={{ top: 10 }}></LineChart>
                </div>
            </div>
        );
    } else {
        return (
            <div id="ZeiteintraegeGraph">
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "5px" }} variant="h5">Zeiteinträge und Anwesenheit</Typography>
                <Select renderValue={(selected) => (<Typography padding={0} variant="h5">{selected}</Typography>)} variant="standard" value={selectedValue} onChange={handleChange} sx={{ '&::before': { borderBottom: 'none !important' }, '&::after': { borderBottom: 'none !important' }, '&:hover::before': { borderBottom: 'none !important' }, '&:hover::after': { borderBottom: 'none !important' }, '&:focus::before': { borderBottom: 'none !important' }, '&:focus::after': { borderBottom: 'none !important' } }}>
                    <MenuItem value="diesen Monat">Dieser Monat</MenuItem>
                    <MenuItem value="letzter Monat">Letzter Monat</MenuItem>
                </Select> 
            </div>
            <div style={{ width: "100%", height: "90%", alignItems: "center", justifyContent:"center" }}>
                <LinearProgress></LinearProgress>
            </div>
        </div>
        )
    }
}