import { useContext, useState, useEffect } from "react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { AssembleQuery, Restquery } from "../queries/AssembleQuery";
import { UserContext } from "./ContextProvider";
import { getLastDayOfYear, getLastDayOfMonthFormatted, getFirstDayOfMonth, getDateHyphenFormat, getCurrentDate, getYear } from "../utils/DateUtils";

import '../styles/UeberzeitenView.module.css'

export const UeberzeitenView = () => {
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const { instance } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    let currentEmail = String(activeAccount.username)

    const monthStart = getFirstDayOfMonth()
    const monthEnde = getLastDayOfMonthFormatted()
    const LastDayOfTheYear = getLastDayOfYear()
    const currentYear = getYear()

    let requestPara = new Restquery()
    requestPara.EntityName = "xrm1_employees"
    requestPara.FilterCriteria = "emailaddress eq '" + currentEmail + "'"

    let requestMitarbeiter = AssembleQuery(requestPara)

    const [OvertimeImMonat, setOvertimeImMonat] = useState(null)
    const [OvertimeImJahr, setOvertimeImJahr] = useState(null)
    const [OvertimeImMonatDays, setOvertimeImMonatDays] = useState(null)
    const [UrlaubstageDiesesJahr, setUrlaubstageDiesesJahr] = useState(null)

    const { execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    useEffect(() => {
        if (!UrlaubstageDiesesJahr) {
                var requestAB = "xrm1_time_summary_dailies?$filter=xrm1_date gt " + monthStart + " and xrm1_date le " + monthEnde + " and _xrm1_time_summary_daily_employee_id_value eq " + MitarbeiterGUID +"&$select=xrm1_hours_overtime,xrm1_date&$orderby=xrm1_date"
                execute("GET", protectedResources.Dynamics365.endpoint + requestAB).then((response) => {
                    const Arbeitszeit = response["value"]
                        .map((Zeit) => Zeit["xrm1_hours_overtime"])
                        .filter((value) => value !== null);
    
                    const sumAllExceptLatest = Arbeitszeit.slice(0, -1).reduce((acc, curr) => acc + curr, 0).toFixed(2);
    
                    const TimeDifferenceDays = response["value"]
                        .map((Zeit) => {
                            if (Zeit["xrm1_hours_overtime"] !== 0 && Zeit["xrm1_hours_overtime"] !== null && Zeit["xrm1_date"].startsWith(getCurrentDate()) === false) {// also filter for not equal to current date in iso string with time set to 00:00:00
    
                                return (
                                    <tr className="overtimeDayItem" key={String(Zeit["xrm1_date"])}>
                                        <td className="label">{getDateHyphenFormat(Zeit["xrm1_date"])}</td>
                                        <td className="value">{Zeit["xrm1_hours_overtime"]} Stunden</td>
                                    </tr>
                                )
                            }
                        })
                    setOvertimeImMonat(sumAllExceptLatest)
                    setOvertimeImMonatDays(TimeDifferenceDays)
                });
                var requestUT = "xrm1_time_contingents?$filter=xrm1_absence_type eq 693100000" + " and xrm1_end_date eq " + LastDayOfTheYear + " and _xrm1_time_contingent_employee_id_value eq " + MitarbeiterGUID
                execute("GET", protectedResources.Dynamics365.endpoint + requestUT).then((response) => {
                    const Urlaubstage = response["value"][0]["xrm1_days_remaining"]
                    setUrlaubstageDiesesJahr(Urlaubstage)
                });
        }
    }, [execute, OvertimeImMonat, UrlaubstageDiesesJahr]);

    return (
        <div id="Ueberzeiten">
            <Typography variant="h5">Überzeiten</Typography>
            <div id="overtimeViewWrapper">
                <table id="overtimeView">
                    <thead>
                        <tr>
                            <td className="label">Stundenübersicht</td>
                            <td>Diesen Monat</td>
                            <td>Dieses Jahr</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="label">Überstunden: </td>
                            <td >{OvertimeImMonat} Stunden</td>
                            <td>{OvertimeImJahr} Stunden</td>
                        </tr>
                    </tbody>
                </table>
                <div id="spacerOvertime"></div>
                <table id="HolidaysView">
                    <thead>
                        <tr>
                            <td className="label">Urlaubsübersicht</td>
                            <td className="value">Dieses Jahr</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="label">Urlaubstage: </td>
                            <td className="value">{UrlaubstageDiesesJahr} Tage</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="spacerOvertime"></div>
            <div id="overtimeDays">
                <table id="daysWithOvertime">
                    <thead>
                        <tr key="header-OVD">
                            <td className="label">Tage mit Stundenabweichung</td>
                            <td className="value">diesen Monat</td>
                        </tr>
                    </thead>
                    <tbody className="daysWithOvertimeBody">
                        {OvertimeImMonatDays}
                    </tbody>
                </table>
            </div>
        </div>
    );
}