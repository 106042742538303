import { protectedResources } from '../authConfig';
import { AssembleQuery, Restquery } from '../queries/AssembleQuery';
import useFetchWithMsal from '../hooks/useFetchWithMsal'
import { useEffect, useState } from 'react';

function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

export const AnwesenheitsdetailItem = (details) => {
    var name = details.name
    if (isEmptyObject(details.PausenZeit)) {
        if (details.WorkHours.condition != "none") {
            return (
                <tr className='AnwesenheitsdetailItem'>
                <td className='AnwesenheitsdetailNameTd'>
                    {name}
                </td>
                <td className='VergangeneZeit'>
                    Brutto Arbeitszeit: {details.WorkHours.condition.hours}:{details.WorkHours.condition.minutes}
                </td>
            </tr>
            );
        } else {
            return (
                <tr className='AnwesenheitsdetailItem'>
                    <td className='AnwesenheitsdetailNameTd'>
                        {name}
                    </td>
                    <td className='VergangeneZeit'>
                    </td>
                </tr>
            );
        }

    } else if (isEmptyObject(details.WorkHours)) {
        return(
            <tr className='AnwesenheitsdetailItem'>
            <td className='AnwesenheitsdetailNameTd'>
                {name}
            </td>
            <td className='VergangeneZeit'>
                Pausenzeit: {details.PausenZeit.hours}:{details.PausenZeit.minutes}
            </td>
        </tr>
        );
    }
    else if (isEmptyObject(details.PausenZeit) && isEmptyObject(details.WorkHours)) {
        console.log("returned no Vergangene Zeit")
        return (
            <tr className='AnwesenheitsdetailItem'>
                <td className='AnwesenheitsdetailNameTd'>
                    {name}
                </td>
                <td className='VergangeneZeit'>
                </td>
            </tr>
        );
    } else {
        console.log("no statement True")
    }
}