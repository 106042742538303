import { useMsal } from "@azure/msal-react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { useState, useEffect } from "react";
import { StateHoverIcon } from "./MicrosoftToDoStateIcon";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Select, MenuItem, InputLabel, Skeleton } from "@mui/material";
import {FormControl} from "@mui/material";
import {Typography} from "@mui/material";

export const ToDoView = () =>  {
    const { instance } = useMsal();

    const handleCallback = () => {
        console.log("refreshing ToDos")
        setToDoLists(null)
    }

    const handleOpen = (ToDoItem) => {
        console.log("opened")
        window.open("https://to-do.office.com/tasks/id/" + ToDoItem["id"] + "/details", "_blank")
    }

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const queryName = String(activeAccount.name).split(" ")

    const [ToDoItemsRender, setToDoItemsRender] = useState();

    const [ToDoListSelectorRender, setToDoListSelectorRender] = useState("Listen werden geladen...");

    const [selectedList, setSelectedList] = useState("");

    var request = "/me/todo/lists"
    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.ToDo.scope]
    });

    const [ToDoLists, setToDoLists] = useState(null);

    const handleClick = (event, id, ListName) => {
        setToDoLists(id)
        setSelectedList(ListName)
    }

    useEffect(() => {
        if (!ToDoLists) {
            execute("GET", protectedResources.ToDo.endpoint + request).then((response) => {
                var QuerySelected = 0
                if (ToDoListSelectorRender == "Listen werden geladen...") {
                    if(response["value"] != undefined) {
                        var ToDoListsData = response["value"]
                        const ToDoListSelectors = new Array()
                        ToDoListSelectors.push(<MenuItem key={"MyDay"} value={"Mein Tag"} id={"MyDay"} onClickCapture={(event) => handleClick(event, "MyDay", "Mein Tag")}>{"Mein Tag"}</MenuItem>)
                        ToDoListSelectors.push(                        
                            ToDoListsData.map(List => {
                            return (
                                <MenuItem key={List["id"]} value={List["displayName"]} id={List["id"]} onClickCapture={(event) => handleClick(event, List["id"], List["displayName"])}>{List["displayName"]}</MenuItem>
                            )
                        }))
                        setToDoListSelectorRender(ToDoListSelectors);
                    }
                    
                }
                for(const ToDoList of response["value"]) {
                    if (String(ToDoList["displayName"]).includes(queryName[0])) {
                        setSelectedList(ToDoList["displayName"])
                        const newRequest = "/me/todo/lists/" + String(ToDoList["id"]) +"/tasks?$filter=(status ne 'completed')"
                        execute("GET", protectedResources.ToDo.endpoint + newRequest).then((response) => {
                            var ToDoItemsData = response["value"]
                            const ToDoItems = ToDoItemsData.map(ToDoItem =>
                                <tr key={ToDoItem["id"]} id={ToDoItem["id"]} className="ToDoListItem">
                                    <td className="stateIcon"><StateHoverIcon key={ToDoItem["id"] + "_Icon"} list={String(ToDoLists)} keyInput={ToDoItem["id"]} completeCallback={handleCallback}></StateHoverIcon></td>
                                    <td className="Title">{ToDoItem["title"]}</td>
                                    <td className="dueDateTime"></td>
                                    <td className="openToDoIcon"><IconButton className="OpenIn" onClick={(TodoItem) => handleOpen(ToDoItem)}><OpenInNewIcon></OpenInNewIcon></IconButton></td>
                                </tr>
                            )
                            setToDoItemsRender(ToDoItems)
                        });
                        QuerySelected = 1
                    } 
                }
                if (QuerySelected == 0) {
                    console.log("No List found")
                    for (const ToDoList of response["value"]) {
                        if (String(ToDoList["wellknownListName"]) == "defaultList") {
                            const newRequest = "/me/todo/lists/" + String(ToDoList["id"]) +"/tasks?$filter=(status ne 'completed')"
                            execute("GET", protectedResources.ToDo.endpoint + newRequest).then((response) => {
                                //Has to update the ToDos
                                console.log(response["value"])
                            });
                        }
                    }
                }
                setToDoLists(response["value"])
            });
        } else if (typeof ToDoLists === 'string') {
            if (ToDoLists === "MyDay") {
                window.open("https://to-do.office.com/tasks/myday", "_blank")
            } else {
                const newRequest = "/me/todo/lists/" + ToDoLists +"/tasks?$filter=(status ne 'completed')"
                execute("GET", protectedResources.ToDo.endpoint + newRequest).then((response) => {
                    //Has to update the ToDos
                    const ToDoItems = response["value"].map(ToDoItem =>
                        <tr key={ToDoItem["id"]} id={ToDoItem["id"]} className="ToDoListItem">
                            <td className="stateIcon"><StateHoverIcon key={ToDoItem["id"] + "_Icon"} list={String(ToDoLists)} keyInput={ToDoItem["id"]} completeCallback={handleCallback}></StateHoverIcon></td>
                            <td className="Title">{ToDoItem["title"]}</td>
                            <td className="dueDateTime"></td>
                            <td className="openToDoIcon"><IconButton className="OpenIn" onClick={(TodoItem) => handleOpen(TodoItem)}><OpenInNewIcon></OpenInNewIcon></IconButton></td>
                        </tr>
                    )
                    setToDoItemsRender(ToDoItems)
                });     
            }
        }
    }, [execute, ToDoLists])

    if (ToDoLists) {
        return (
            <div id="ToDoList">
                <div id="ToDoHeader">
                    <Typography variant="h5">ToDos</Typography>
                    <FormControl size="small" sx={{ m: 1,minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">Liste:</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedList}
                            label="ToDoList"
                            variant="outlined"
                        >
                            {ToDoListSelectorRender}
                        </Select>
                    </FormControl> 
                </div>
                <table id="ToDoTable">
                    <thead>
                        <tr>
                            <td className="stateIcon"></td>
                            <td className="Title"></td>
                            <td className="dueDateTime"></td>
                            <td className="openToDoIcon"></td>
                        </tr>
                    </thead>
                    <tbody id="ToDoItems">
                        {ToDoItemsRender}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div id="ToDoList">
            <div id="ToDoHeader">
                <Typography variant="h5">ToDos</Typography>
            </div>
            <Skeleton variant="text"></Skeleton>
        </div>
        )
    }
}