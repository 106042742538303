import React, { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { UserContext } from "../components/ContextProvider"
import { Typography, Button, IconButton, Box } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid"
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';
import { styled } from '@mui/material/styles';
import { AlertComponent } from '../components/ErrorModal';
import moment from "moment";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import '../styles/Anwesenheit.module.css'

const months = {
    1: "Januar",
    2: "Februar",
    3: "März",
    4: "April",
    5: "Mai",
    6: "Juni",
    7: "Juli",
    8: "August",
    9: "September",
    10: "Oktober",
    11: "November",
    12: "Dezember"
}

const weekdays = {
    1: "Montag",
    2: "Dienstag",
    3: "Mittwoch",
    4: "Donnerstag",
    5: "Freitag",
    6: "Samstag",
    0: "Sonntag"
}

const zeitstempelTypes = {
    693100000: "Arbeitsbeginn",
    693100003: "Arbeitsende",
    693100005: "Home-Office antreten",
    693100008: "Home-Office beenden",
    693100001: "Pausenbeginn",
    693100002: "Pausenende",
    693100006: "Dienstgang antreten",
    693100009: "Dienstgang beenden",
    693100004: "Reise antreten",
    693100007: "Reise beenden",
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  

function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Keine Einträge vorhanden, oder es ist ein Fehler beim laden aufgetreten</Box>
      </StyledGridOverlay>
    );
  }

export const MeineAnwesenheit = () => {
    const navigate = useNavigate()
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const currentYear = moment().year()
    const gridApiRef = useGridApiRef()

    const [rows, setrows] = useState(new Array)
    const [fetched, setFetched] = useState(false)
    const [loading, setLoading] = useState(false)

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const columns = [
        {field: "xrm1_month_str", headerName: "Monat", width: 150},
        {field: "xrm1_year", headerName: "Jahr", width: 150},
        {field: "xrm1_hours_allocated", headerName: "Sollstunden", width: 150},
        {field: "xrm1_hours_attended", headerName: "Stunden Anwesend", width: 150},
        {field: "xrm1_hours_leave", headerName: "Stunden Abwesend", width: 150},
        {field: "xrm1_hours_overtime", headerName: "Überstunden", width: 150},
    ]

    const autosizeOptions = {columns: ["xrm1_month", "xrm1_year","xrm1_hours_allocated","xrm1_hours_attended","xrm1_hours_leave","xrm1_hours_overtime"], includeOutliers: true, includeHeaders: true}

    useEffect(() => {
        if (fetched === false) {
            setLoading(true)
            execute("GET", protectedResources.Dynamics365.endpoint + "/xrm1_time_summaries?$filter=_xrm1_time_summary_employee_id_value eq " + MitarbeiterGUID + " and xrm1_year eq '" + currentYear + "'" + "&$orderby=xrm1_start_date").then(response => {
                if (response && response["value"] && Array.isArray(response["value"])) {
                    let responseVal = response["value"]
                    
                    responseVal.forEach(obj => {
                        let lastTwo = obj.xrm1_month % 100
                        obj.xrm1_month_str = months[lastTwo]
                    })

                    setrows(responseVal)
                    setLoading(false)
                    setFetched(true)
                }
            })
        }
    }, [execute, rows])

    const handleOpenSelected = () => {
        const selectedItems= gridApiRef.current.getSelectedRows()
        selectedItems.forEach((value, key) => {
            window.open("https://zeiten.nms.de/Anwesenheit/" + key, "_blank")
        });
    }

    const handleOpenSingle = (params) => {
        navigate("/Anwesenheit/" + params.row.xrm1_time_summaryid)
    }

    return (
        <div className="MeineAnwesenheitMain">
            <div style={{display: "flex"}}>
                <Typography variant="h4">Meine Anwesenheiten</Typography>
                <div style={{width: "7px"}}></div>
                <Button variant="contained" onClick={handleOpenSelected}>Ausgewählte öffnen</Button>
            </div>
            <div style={{height: "7px"}}></div>
            <div style={{height: "7px"}}></div>
            <DataGrid loading={loading} slots={{ noRowsOverlay: CustomNoRowsOverlay }} apiRef={gridApiRef} onCellDoubleClick={(params) => {handleOpenSingle(params)}} checkboxSelection={true} getRowId={(row) => { return row.xrm1_time_summaryid}} columns={columns} rows={rows} autosizeOnMount={true} autosizeOptions={autosizeOptions}></DataGrid>
        </div>
    );
}

// Shows daily summaries associated with the given Time summary
export const DailySummaries = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const gridApiRef = useGridApiRef()

    const [rows, setrows] = useState(new Array)
    const [fetched, setFetched] = useState(false)
    const [loading, setLoading] = useState(false)

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const columns = [
        {field: "xrm1_date_str", headerName: "Datum", width: 150},
        {field: "xrm1_weekday_str", headerName: "Wochentag", width: 150},
        {field: "xrm1_holiday_str", headerName: "Feiertag", width: 150},
        {field: "xrm1_hours_allocated", headerName: "Sollstunden", width: 150},
        {field: "xrm1_hours_attended", headerName: "Stunden Anwesend", width: 150},
        {field: "xrm1_hours_leave", headerName: "Stunden Abwesend", width: 150},
        {field: "xrm1_hours_overtime", headerName: "Überstunden", width: 150},
    ]

    useEffect(() => {
        if (!fetched) {
            setLoading(true)
            execute("GET", protectedResources.Dynamics365.endpoint + "/xrm1_time_summary_dailies?$filter=_xrm1_time_summary_daily_employee_id_value eq " + MitarbeiterGUID + " and _xrm1_daily_summary_time_summary_id_value eq " + id + "&$orderby=xrm1_date").then(response => {
                if (response && response["value"] && Array.isArray(response["value"])) {
                    let responseVal = response["value"]

                    responseVal.forEach(obj => {
                        let lastTwo = obj.xrm1_weekday % 100
                        obj.xrm1_weekday_str = weekdays[lastTwo]

                        obj.xrm1_date_str = moment(obj.xrm1_date).format("DD.MM.YYYY")
                        
                        if (obj.xrm1_holiday === 693100000) {
                            obj.xrm1_holiday_str = "Ja"
                        }
                    })

                    setrows(responseVal)
                    setLoading(false)
                    setFetched(true)
                }
            })
        }
    }, [execute, rows])

    const handleOpenSelected = () => {
        const selectedItems= gridApiRef.current.getSelectedRows()
        selectedItems.forEach((value, key) => {
            window.open("https://zeiten.nms.de/Anwesenheitsdetails/" + key, "_blank")
        });
    }

    const handleOpenSingle = (params) => {
        navigate("/Anwesenheitsdetails/" + params.row.xrm1_time_summary_dailyid)
    }

    return (
        <div className="MeineAnwesenheitMain">
            <div style={{display: "flex"}}>
                <Typography variant="h4">Meine Täglichen Übersichten</Typography>
                <div style={{width: "7px"}}></div>
                <Button variant="contained" onClick={handleOpenSelected}>Ausgewählte öffnen</Button>
            </div>
            <div style={{height: "7px"}}></div>
            <div style={{height: "7px"}}></div>
            <DataGrid loading={loading} slots={{ noRowsOverlay: CustomNoRowsOverlay }} onCellDoubleClick={(params) => {handleOpenSingle(params)}} apiRef={gridApiRef} checkboxSelection={true} getRowId={(row) => { return row.xrm1_time_summary_dailyid}} columns={columns} rows={rows}></DataGrid>
        </div>
    );
}

// Shows Anwesenheitsdetails associated with the given Daily summary
export const Anwesenheitsdetails = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const gridApiRef = useGridApiRef()

    const [rows, setrows] = useState(new Array)
    const [fetched, setFetched] = useState(false)
    const [loading, setLoading] = useState(false)
    const [AlertComponentVAR, setAlertComponentVAR] = useState(null)

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const columns = [
        {field: "xrm1_date_str", headerName: "Datum", width: 150},
        {field: "xrm1_type_str", headerName: "Typ", width: 150},
        {field: "xrm1_time_str", headerName: "Uhrzeit", width: 150},
    ]

    useEffect(() => {
        if (!fetched) {
            setLoading(true)
            execute("GET", protectedResources.Dynamics365.endpoint + "/xrm1_attendances?$filter=_xrm1_attendance_time_summary_daily_id_value eq " + id + "&$select=xrm1_attendanceid").then(response => {
                if (response !== undefined) {
                    if (response["value"].length !== 0) {
                        execute("GET", protectedResources.Dynamics365.endpoint + "/xrm1_attendance_details?$filter=_xrm1_attendance_detail_attendance_id_value eq " + response["value"][0]["xrm1_attendanceid"] + "&$orderby=xrm1_start_date").then(response => {
                            if (response && response["value"] && Array.isArray(response["value"])) {
                                let responseVal = response["value"]
            
                                responseVal.forEach(obj => {
                                    obj.xrm1_type_str = zeitstempelTypes[obj.xrm1_type]
            
                                    obj.xrm1_date_str = moment(obj.xrm1_start_date).format("DD.MM.YYYY")
    
                                    obj.xrm1_time_str = moment(obj.xrm1_start_date).format("HH:mm")
                                })
            
                                setrows(responseVal)
                                setLoading(false)
                                setFetched(true)
                            }
                        })
                    } else {
                        setLoading(false)
                        setFetched(true)
                    }
                }
            })
        }
    }, [execute, rows])

    const closeCallback = () => {
        setAlertComponentVAR(null)
    }

    const handleChangeRequest = () => {
        let subject = encodeURIComponent("Änderung für bestenden Zeitstempel");
        let bodyRaw = 'Hallo zusammen, \n\n für folgende Zeitstempel müssen Änderungen vorgenommen werden:\n';
        const selectedItems = gridApiRef.current.getSelectedRows()
        console.log(selectedItems.size)
        if (selectedItems.size === 0) {
            setAlertComponentVAR(<AlertComponent closeCallback={closeCallback} showMo={true} message={"Bitte wähle erst einen Zeitstempel aus"} status={"info"}></AlertComponent>)
        } else {
            let body
            selectedItems.forEach((value, key) => {
                console.log(value)
                bodyRaw += '\n' + String(value["xrm1_name"]) + ' | neuer Eintrag:  | Link zur Anwesenheit: https://nms24.crm4.dynamics.com/main.aspx?appid=b6b166aa-bda1-eb11-b1ac-000d3a2ba37a&pagetype=entityrecord&etn=xrm1_attendance&id=' + value["_xrm1_attendance_detail_attendance_id_value"]
            });
            body = encodeURIComponent(bodyRaw)
            let mailtoLink = `mailto:zeiten@nms.de?subject=${subject}&body=${body}`;
            window.location.href = mailtoLink
        }
    }

    return (
        <>
            <div className="MeineAnwesenheitMain">
                <div style={{display: "flex"}}>
                    <Typography variant="h4">Meine Zeitstempel</Typography>
                    <div style={{width: "7px"}}></div>
                    <Button variant="contained" onClick={handleChangeRequest}><CheckBoxIcon sx={{marginRight:"5px"}}></CheckBoxIcon> Änderung beantragen</Button>
                </div>
                <div style={{height: "7px"}}></div>
                <div style={{height: "7px"}}></div>
                <DataGrid loading={loading} slots={{ noRowsOverlay: CustomNoRowsOverlay }} apiRef={gridApiRef} checkboxSelection={true} getRowId={(row) => { return row.xrm1_attendance_detailid}} columns={columns} rows={rows}></DataGrid>
            </div>
            {AlertComponentVAR}
        </>

    );
}