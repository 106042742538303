import { createContext, useEffect, useState } from "react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { useMsal } from '@azure/msal-react';
import { LinearProgress } from "@mui/material";
import {Alert} from "@mui/material";

export const UserContext = createContext(); // Exporting the context

export const ContextProvider = ({ children, dummy }) => {
    const { instance } = useMsal();

    let activeAccount;
    let currentEmail

    if (instance !== null) {
        try {
            activeAccount = instance.getActiveAccount();
            currentEmail = String(activeAccount.username)
        } catch (MSALerror) {
            console.log("MSAL Error: " + MSALerror)
        }
    }

    

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const [MitarbeiterGUID, setMitarbeiterGUID] = useState(null);
    const [SystemuserGUID, setSystemuserGUID] = useState(null);

    useEffect(() => {
        async function getData() {
            if (dummy === true) {
                setMitarbeiterGUID("undefined")
                setSystemuserGUID("undefined")
            }

            if (MitarbeiterGUID === null && dummy !== true) {
                let requestMitarbeiter = "xrm1_employees?$filter=emailaddress eq '" + currentEmail + "'";
                const response = await execute("GET", protectedResources.Dynamics365.endpoint + requestMitarbeiter);
                if (response["value"][0]) {
                    const CurrentMitarbeiter = response["value"][0]["xrm1_employeeid"];
                    setMitarbeiterGUID(CurrentMitarbeiter);
                } else {
                    const CurrentMitarbeiter = "none"
                    setMitarbeiterGUID(CurrentMitarbeiter);
                }

            }

            if (SystemuserGUID === null && MitarbeiterGUID !== undefined && dummy !== true) {
                let requestSystemuser = "systemusers?$filter=domainname eq '" + currentEmail + "'";
                const response = await execute("GET", protectedResources.Dynamics365.endpoint + requestSystemuser);
                if (response["value"][0] != undefined) {
                    const CurrentSystemuser = response["value"][0]["systemuserid"];
                    setSystemuserGUID(CurrentSystemuser);
                } else {
                    const CurrentSystemuser = "none"
                    setSystemuserGUID(CurrentSystemuser);
                }
            }
        }

        getData();
    }, [MitarbeiterGUID, SystemuserGUID, currentEmail, execute]);

    if (instance.getAllAccounts().length == 0) {
        return (
            <UserContext.Provider value={undefined}>
                {children}
            </UserContext.Provider>
        );
    }


    let authContextValue = {
        MitarbeiterGUID,
        SystemuserGUID
    };

    console.log(authContextValue)
    if (SystemuserGUID == "none" || MitarbeiterGUID == "none") {
        return (
            <UserContext.Provider value={authContextValue}>
                <Alert open={true} variant="filled" severity="error">Du wurdest nicht als Mitarbeiter im System gefunden, bitte erstelle ein Ticket bei support@nms.de</Alert>
                {children}
            </UserContext.Provider>
        );
    } else if (SystemuserGUID !== null && MitarbeiterGUID !== null) {
        return (
            <UserContext.Provider value={authContextValue}>
                {children}
            </UserContext.Provider>
        );
    }  else {
        return (
            <LinearProgress></LinearProgress>
        )
    }
}