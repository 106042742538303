import React, { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from '../authConfig';
import { getDateHyphenFormat } from "../utils/DateUtils"

import '../styles/Anwesenheit.module.css'

export const FlowRuns = (flowID) => {
    const [loadingRuns, setLoadingRuns] = useState(true)
    const [rowsRuns, setRowsRuns] = useState(new Array)
    const [fetchedRuns, setfetchedRuns] = useState(false)

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.PowerAutomate.scopes.ActivityRead]
    });  

    const columnsRuns = [
        {field: "time_str", headerName: "Startzeit", width: 400},
        {field: "status", headerName: "Status", width: 100},
        {field: "code", headerName: "Code", width: 100},
    ]

    useEffect(() => {
        if (!fetchedRuns) {
            setLoadingRuns(true)
            execute("GET", "https://api.flow.microsoft.com/providers/Microsoft.ProcessSimple/environments/5c5deb8e-18aa-49e4-a3d9-7b65dc248717/flows/" + flowID.flowID + "/runs?api-version=2016-11-01").then(response => {
                if (response["value"] && Array.isArray(response["value"])) {
                    let responseVal = response["value"]
  
                    let i = 0
  
                    responseVal.forEach(obj => {
                      obj.id = i
                      i++
                      obj.time_str = getDateHyphenFormat(obj.properties.startTime)
                      obj.code = obj.properties.code
                      obj.status = obj.properties.status
                    })
                    
                    setRowsRuns(responseVal)
                    setLoadingRuns(false)
                    setfetchedRuns(true)
                }
            })
        }
    }, [execute, rowsRuns])

    return (
        <DataGrid initialState={{sorting: {sortModel: [{field: "name", sort: "asc"}]}}} loading={loadingRuns} columns={columnsRuns} rows={rowsRuns}></DataGrid>
    )
}

export const RecentlyFailedFlows = (params) => {
    const [loadingRuns, setLoadingRuns] = useState(true)
    const [rowsRuns, setRowsRuns] = useState(new Array)
    const [fetchedRuns, setFetchedRuns] = useState(false)

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.PowerAutomate.scopes.ActivityRead]
    });  

    const columnsRuns = [
        {field: "name_parent", headerName: "name", width: 400},
        {field: "status", headerName: "Status", width: 100},
        {field: "code", headerName: "Code", width: 100},
    ]

    useEffect(() => {
        if (params.flows && Array.isArray(params.flows)) {
            console.log('Flows:', params.flows);

            // Create a promise array to handle asynchronous requests
            let promises = params.flows.map(flow => {
                console.log('Fetching runs for flow:', flow.workflowidunique);

                return execute("GET", `https://api.flow.microsoft.com/providers/Microsoft.ProcessSimple/environments/5c5deb8e-18aa-49e4-a3d9-7b65dc248717/flows/${flow.workflowidunique}/runs?api-version=2016-11-01`)
                    .then(response => {
                        console.log('Response for flow:', flow.workflowidunique, response);

                        if (response && Array.isArray(response.value)) {
                            let responseVal = response.value;

                            responseVal.forEach((run, index) => {
                                run.id = index;
                                run.Parent = flow.workflowidunique
                                run.name_parent = flow.name
                                run.time_str = getDateHyphenFormat(run.properties.startTime);
                                run.code = run.properties.code;
                                run.status = run.properties.status;
                            });

                            return responseVal;
                        } else {
                            console.warn('Unexpected response structure for flow:', flow.workflowidunique, response);
                            return [];
                        }
                    })
                    .catch(error => {
                        console.error(`Failed to fetch runs for flow ${flow.workflowidunique}:`, error);
                        return [];
                    });
            });

            // Wait for all promises to resolve
            Promise.all(promises)
                .then(results => {
                    // Flatten the results array
                    let allRuns = results.flat();

                    // Compact runs by workflowidunique and set failed status
                    let compactedRuns = {};
                    allRuns.forEach(run => {
                        if (!compactedRuns[run.Parent]) {
                            compactedRuns[run.Parent] = { id: run.Parent, runs: [], name_parent: run.name_parent };
                        }
                        compactedRuns[run.Parent].runs.push(run);

                        // Check for failed status
                        if (run.status === 'Failed') {
                            compactedRuns[run.Parent].status = 'failed';
                        } else {
                            compactedRuns[run.Parent].status = 'succeded';
                        }
                    });

                    console.log(compactedRuns)
                    setRowsRuns(Object.values(compactedRuns));
                    setLoadingRuns(false);
                    setFetchedRuns(true);
                })
                .catch(error => {
                    console.error('Failed to fetch all runs:', error);
                    setLoadingRuns(false);
                    setFetchedRuns(false);
                });
        } else {
            console.warn('No flows to process or flows is not an array');
        }
    }, [params.flows, execute]); // Ensure execute is a dependency

    return (
        <DataGrid initialState={{sorting: {sortModel: [{field: "name", sort: "asc"}]}}} loading={loadingRuns} columns={columnsRuns} rows={rowsRuns}></DataGrid>
    )
}