import { useMemo, useState, useEffect, useContext } from "react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { Skeleton, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { AssembleQuery, Restquery } from "../queries/AssembleQuery";
import { useMsal } from "@azure/msal-react";
import { getFirstDayOfWeek } from "../utils/DateUtils";
import { UserContext } from '../components/ContextProvider';

//Needs rewrite from age to dauer(h)
function sumDurationByCustomerId(data) {
    const groupedData = {};
    data.forEach(obj => {
        const customerId = obj.catbase_customerid.name;
        const duration = obj.catbase_durationhours;
        if (!groupedData[customerId]) {
            groupedData[customerId] = 0;
        }
        groupedData[customerId] += duration;
    });
    return groupedData;
}


export const ZeiteintraegeGraph = () =>  {
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    
    var weekstart = getFirstDayOfWeek()

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const [GroupedData, setGroupedData] = useState(null);
    const [values, setValues] = useState(null)

    useEffect(() => {
        if (!GroupedData) {
            var requestParameters = new Restquery()
            requestParameters.EntityName = "catbase_timeitems"
            requestParameters.FilterCriteria = "catbase_starttime ge '" + weekstart + "'" + " and _owninguser_value eq " + SystemuserGUID
            requestParameters.ExpandNames = "catbase_customerid"
        
            var request = AssembleQuery(requestParameters);

            execute("GET", protectedResources.Dynamics365.endpoint + request).then((response) => {
                if (response["value"] !== null) {
                    const groupedByCustomerId = sumDurationByCustomerId(response["value"])
                    setGroupedData(groupedByCustomerId)
                }
            });
        }
        if (GroupedData) {
            var id = 0 
            var keys = Object.keys(GroupedData);
            let temp = keys.map(key => {
                id++
                return ({id: id, value: GroupedData[key], label: key})
            });
            setValues(temp)
        }
    }, [execute, GroupedData])

    if (values) {
        return (
            <>
                <Typography variant="h5">Zeiteinträge diese Woche nach Kunde</Typography>
                <div style={{height: "80%", width: "100%"}}>
                    <PieChart series={[
                            {
                                data: values,
                                innerRadius: 30,
                                outerRadius: 100,
                                paddingAngle: 5,
                                cornerRadius: 5,
                                startAngle: 0,
                                endAngle: 360,
                                cx: 150,
                                cy: 150,
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            }
                        ]}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
            <Typography variant="h5">Zeiteinträge diese Woche nach Kunde</Typography>
            <Skeleton variant="circular" width={100} height={100}></Skeleton>
        </>
        )
    }
}