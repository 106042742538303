import { useState } from 'react'

export const useDisable = (components = {}) => {
    const [states, setStates] = useState(components)

    const changeStates = (stateToUpdate, newState) => {
        let currentStates = states

        if (stateToUpdate === "all") {
            for (let key in currentStates) {
                if (currentStates.hasOwnProperty(key)) {
                    currentStates[key] = newState
                }
            }
        } else {
            currentStates[stateToUpdate] = newState
        }
        
        setStates(currentStates)
    }

    return [states, changeStates]
} 