import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import { Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react"
import { AssembleQuery, Restquery } from "../queries/AssembleQuery";
import { getDateHyphenFormat } from "../utils/DateUtils";
import { UserContext } from '../components/ContextProvider';
import { getFirstDayOfWeek } from "../utils/DateUtils";

function getLastDayOfWeek() {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
    return lastDay.toISOString().split('T')[0];
}

function convertMinutesToHoursAndMinutes(minutes) {
    if (typeof minutes !== 'number' || minutes < 0) {
        return { error: 'Invalid input. Please provide a non-negative number of minutes.' };
    }

    const hours = Math.floor(minutes / 60)
    const adjustedHours = String(hours).padStart(2, '0');
    const remainingMinutes = String(minutes % 60).padStart(2, '0');

    return { hours: adjustedHours, minutes: remainingMinutes };
}

export const AnwesenheitsView = () =>  {
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const { instance } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    var weekStart = getFirstDayOfWeek()
    var weekEnd = getLastDayOfWeek()

    let currentEmail = String(activeAccount.username)
    const [ArbeitszeitDieseWoche, setArbeitszeitDieseWoche] = useState(null)
    const [ArbeitszeitTable, setArbeitszeitTable] = useState(null)

    useEffect(() => {
        if (!ArbeitszeitDieseWoche) {
            const requestAB = "xrm1_attendances?$filter=xrm1_time_start gt " + weekStart + " and xrm1_time_start le " + weekEnd + " and _xrm1_employee_attendance_employee_id_value eq " + MitarbeiterGUID + "&$orderby=xrm1_time_start"
            execute("GET", protectedResources.Dynamics365.endpoint + requestAB).then((response) => {
                if (response["value"]) {
                    const Arbeitszeit = response["value"]
                    setArbeitszeitDieseWoche(Arbeitszeit)
                }
            });
        }
        if (ArbeitszeitDieseWoche) {
            const Temp = ArbeitszeitDieseWoche.map(item => {
                var Duration = convertMinutesToHoursAndMinutes(item.xrm1_minutes_net)
                var breakDuration = convertMinutesToHoursAndMinutes(item.xrm1_minutes_breaks)
                return (
                    <tr key={item.xrm1_attendanceid} className="TimeItem">
                        <td className="Datum">
                        {getDateHyphenFormat(item.xrm1_record_date)}
                        </td>
                        <td className="Dauer">
                        {Duration.hours + ":" + Duration.minutes}
                        </td>
                        <td className="Beschreibung">
                        {breakDuration.hours + ":" + breakDuration.minutes}
                        </td>
                    </tr>
                    )
            })
            setArbeitszeitTable(Temp)
        }
    }, [execute, ArbeitszeitDieseWoche])
    return (
        <div id="AnwesenheitsView">
            <Typography variant="h5">Anwesenheit diese Woche</Typography>
                <table id="TimeEntries">
                    <thead>
                        <tr className="THEADZTW">
                            <td className="Datum">
                            Datum
                            </td>
                            <td className="Dauer">
                            Dauer (Netto)
                            </td>
                            <td className="Beschreibung">
                            Pause
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {ArbeitszeitTable}
                    </tbody>
                </table>
        </div>
    );
}