export function AssembleQuery(restQuery) {
    function isNullOrEmpty(obj) {
        return (obj == null || obj == undefined || obj == "" || typeof (obj) === "undefined");
    }

    // Create column parameter
    var cols = "";
    if (restQuery.Columns != null && restQuery.Columns.length > 0)
        cols = "?$select=" + restQuery.Columns.join(",").toLowerCase();

    // Create filter parameter
    if (restQuery.FilterCriteria != null && restQuery.FilterCriteria.length > 0) {
        if (!isNullOrEmpty(cols)) 
            cols = cols + "&";
        
        
        cols += "?$filter=" + restQuery.FilterCriteria;
    }

    // Create expand parameter
    if (restQuery.ExpandNames != null && restQuery.ExpandNames.length > 0) {
        if (!isNullOrEmpty(cols))
            cols = cols + "&";

        cols += "$expand=" + restQuery.ExpandNames;
    }

    // Create order parameter
    if (restQuery.OrderByNames != null && restQuery.OrderByNames.length > 0) {
        if (!isNullOrEmpty(cols))
            cols = cols + "&";

        cols += "$orderby=" + restQuery.OrderByNames.join(",").toLowerCase();
    }

    return String(restQuery.EntityName.toLowerCase() + cols)
}

export function Restquery() {

    // Name of the entity (lower case)
    this.EntityName = "";

    // List of columns to get the data for (lower case)
    this.Columns = new Array();

    // List of filter criteria (lower case)
    this.FilterCriteria = new Array();

    // List of Expand names (lower case)
    this.ExpandNames = new Array();

    // List of Order names (lower case)
    this.OrderByNames = new Array();
}