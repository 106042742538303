import { protectedResources } from '../authConfig';
import { AssembleQuery, Restquery } from '../queries/AssembleQuery';
import useFetchWithMsal from '../hooks/useFetchWithMsal'
import React, { useEffect, useState, useContext } from 'react';
import { AnwesenheitsdetailItem } from './AnwesenheitsdetailItem';
import { Typography, Skeleton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { UserContext } from '../components/ContextProvider';
import { getDateHyphenFormat, getTimeFromDate } from '../utils/DateUtils';

function getHoursAndMinutesCurrentTime() {
    // Convert dateTimeString to a Date object
    const dateTime = new Date();

    // Check if the conversion was successful
    if (isNaN(dateTime.getTime())) {
        throw new Error('Invalid date string');
    }

    // Get hours and minutes from the Date object
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');

    // Construct and return the time string
    return `${hours}:${minutes}`;
}

function calcWorkTime(time1, time2, subtractor = {hours: 0, minutes: 0}) {
    // Parse the time strings to Date objects
    const date1 = new Date(`2000-01-01T${time1}`);
    const date2 = new Date(`2000-01-01T${time2}`);

    // Calculate the time difference in milliseconds
    const timeDifferenceMs = date2 - date1;

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));

    const adjustedHours = String(hours - subtractor.hours).padStart(2, '0')
    const adjustedMinutes = String(minutes - subtractor.minutes).padStart(2, '0')
    return { hours: adjustedHours, minutes: adjustedMinutes }

}

function getTimeDifference(time1, time2) {
    // Parse the time strings to Date objects
    const date1 = new Date(`2000-01-01T${time1}`);
    const date2 = new Date(`2000-01-01T${time2}`);

    // Calculate the time difference in milliseconds
    const timeDifferenceMs = date2 - date1;

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));

    const adjustedHours = String(hours).padStart(2, '0')
    const adjustedMinutes = String(minutes).padStart(2, '0')
    return { hours: adjustedHours, minutes: adjustedMinutes }

}

export const AnwesenheitsdetailsView = ({refreshContext, refreshCompleted}) => {
    const { MitarbeiterGUID, SystemuserGUID } = useContext(UserContext)
    const { instance } = useMsal();

    let activeAccount;
  
    if (instance) {
      activeAccount = instance.getActiveAccount();
    }
  
    let currentEmail = String(activeAccount.username)

    let requestParametersMitarbeiter = new Restquery()
    requestParametersMitarbeiter.EntityName = "xrm1_employees"
    requestParametersMitarbeiter.FilterCriteria = "emailaddress eq '" + currentEmail + "'"
  
    let requestMitarbeiter = AssembleQuery(requestParametersMitarbeiter)

    const currentDate = getDateHyphenFormat()



    const { error, execute } = useFetchWithMsal({
        scopes: [protectedResources.Dynamics365.scope]
    });

    const [Anwesenheiten, setAnwesenheiten] = useState(null);

    useEffect(() => {
        if (refreshContext == true) {
            setAnwesenheiten(null)
            refreshCompleted({"1": "Anwesenheiten"})
        }
        if (!Anwesenheiten) {
            var requestParameters = new Restquery()
            requestParameters.EntityName = "xrm1_attendance_details"
            requestParameters.FilterCriteria = "contains(xrm1_name,'" + currentDate + "') and _xrm1_attendance_detail_employee_id_value eq " + MitarbeiterGUID
        
            var request = AssembleQuery(requestParameters);
            
            execute("GET", protectedResources.Dynamics365.endpoint + request).then((response) => {
                setAnwesenheiten(response["value"])
            });
        }
    }, [execute, Anwesenheiten])

    let AnwesenheitsItems
    
    if (Anwesenheiten) {
        let PausenZeitenGesamt = {hours: 0, minutes: 0}
        if (Anwesenheiten.length >= 1){
            const sortedData = Anwesenheiten.sort((a, b) => {
                // Extracting the xrm1_name attribute from both objects
                const nameA = a.xrm1_name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.xrm1_name.toUpperCase(); // ignore upper and lowercase
            
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            
                // names must be equal
                return 0;
            });
            AnwesenheitsItems = sortedData.map(eintrag => (
                <AnwesenheitsdetailItem
                    name={eintrag.xrm1_name} 
                    key={eintrag.xrm1_attendance_detailid} 
                    id={eintrag.xrm1_attendance_detailid} 
                    startTime={eintrag.xrm1_start_date}
                    PausenZeit={{}}
                    WorkHours={{}}
                />
            ));

            const updatedAnwesenheitsItems = AnwesenheitsItems.map((item, index) => {
                if (index >= 0) {
                    const fullCurrentText = item.props.name;
                    let fullPreviousText
                    if (AnwesenheitsItems[index - 1] != undefined){
                        fullPreviousText = AnwesenheitsItems[index - 1].props.name;
                    } else {
                        fullPreviousText = ""
                    }
                    const currentText = fullCurrentText.split(" - ")[1];
                    const previousText = fullPreviousText.split(" - ")[1];
            
                    if (currentText.includes("Pausenende") && previousText.includes("Pausenbeginn")) {
                        const TimeStamp1 = getTimeFromDate(item.props.startTime);
                        const TimeStamp2 = getTimeFromDate(AnwesenheitsItems[index - 1].props.startTime);
            
                        const difference = getTimeDifference(TimeStamp2, TimeStamp1);
            
                        // Create a new object with updated PausenZeit prop
                        return React.cloneElement(item, { PausenZeit: difference });
                    } else {
                        const TimeStamp1 = getTimeFromDate(item.props.startTime);
                        const filteredData = sortedData.filter(eintrag => 
                            eintrag.xrm1_name.includes("Arbeitsbeginn") ||
                            eintrag.xrm1_name.includes("antreten")
                        );
                        return React.cloneElement(item, {
                            WorkHours: {
                                condition: (currentText.includes("Arbeitsbeginn") || currentText.includes("antreten")) ? calcWorkTime(TimeStamp1, getHoursAndMinutesCurrentTime(), PausenZeitenGesamt) :
                                           (currentText.includes("Arbeitsende") || currentText.includes("beenden")) ? calcWorkTime(getTimeFromDate(filteredData[0].xrm1_start_date), TimeStamp1, PausenZeitenGesamt) :
                                           "none"
                            }
                        });
                    }
                }
                return item;
            });

            AnwesenheitsItems = updatedAnwesenheitsItems
        }
    }

    if (!AnwesenheitsItems) {
        return (
            <div id="Anwesenheitsdetails">
                <Typography variant="h5">Meine heutigen Zeitstempel</Typography>
                <Stack spacing={1}>
                    <table>
                    <thead>

                    </thead>
                    <tbody>
                        <tr>
                            <td>
                            <Skeleton variant='text'></Skeleton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <Skeleton variant='text'></Skeleton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <Skeleton variant='text'></Skeleton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <Skeleton variant='text'></Skeleton>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </Stack>
            </div>
        )
    } else {
        return (
            <div id="Anwesenheitsdetails">
                <Typography variant="h5">Meine heutigen Zeitstempel</Typography>
                <table>
                    <thead>

                    </thead>
                    <tbody>
                        {AnwesenheitsItems}
                    </tbody>
                </table>
            </div>
        );
    }
}