import moment from "moment";

export function getDateHyphenFormat(Dateinput) {
    // Create a Moment.js object from the input date
    const currentDate = moment(Dateinput);

    // Format the date in the desired format using Moment.js
    return currentDate.format('DD.MM.YYYY');
}

export function getDateTimeHyphenFormat(Dateinput) {
    // Create a Moment.js object from the input date
    const currentDate = moment(Dateinput);

    // Format the date in the desired format using Moment.js
    return currentDate.format('DD.MM.YYYY hh:mm');
}

export function getDateHyphenFormatSelector(Dateinput) {
    // Create a Moment.js object from the input date
    const currentDate = moment.unix(Dateinput);

    // Format the date in the desired format using Moment.js
    return currentDate.format('DD.MM.YYYY');
}

export function getCurrentDate() {
    const currentDate = moment();

    return currentDate.toISOString().split('T')[0];
}

export function getYear() {
    const now = moment();
    const year = now.year();

    return year
}

export function getFirstDayOfWeek() {
    const today = moment();

    const startOfWeek = today.startOf("week")
    
    // Format the date as "YYYY-MM-DDTHH:MM:SSZ"
    const hours = '00';
    const minutes = '00';
    const seconds = '00';
    
    return startOfWeek.format("YYYY-MM-DD") + `T${hours}:${minutes}:${seconds}Z`;
}

export function getTimeFromDate(dateTimeString) {
    // Convert dateTimeString to a Date object
    const dateTime = moment(dateTimeString);

    // Construct and return the time string
    return dateTime.format("HH:mm");
}

export function minutesDifference(isoDate1, isoDate2) {
    // Convert ISO strings to Date objects
    const date1 = moment(isoDate1);
    const date2 = moment(isoDate2);
    
    // Convert milliseconds to hours
    const differenceInHours = date2.diff(date1, "minutes");
    
    return differenceInHours.toFixed(2);
}

export function convertDatetoDate8am(DateInput) {
    const date = moment(DateInput)

    // Format the time as ISO String in UTC
    const isoDateString = date.format("YYYY-MM-DD") + `T07:00:00Z`;

    return isoDateString;
}

export function convertToUTC(dateInput) {
    const date = moment(dateInput)

    return date.utc().format();
}

export function convertDatetoDate12am(DateInput) {
    const date = moment(DateInput)

    return date.format("YYYY-MM-DD") + `T00:00:00Z`;
}

export function convertDateTimePickerToISO(DateTimeValue) {
    if (typeof DateTimeValue == Object) {
        try {
            const dateTime = moment.unix(DateTimeValue["$d"])
            return dateTime.format("YYYY-MM-DDTHH:mm")
        } catch(error) {
            console.log("error when creating date time object from input" + error)
            return undefined
        }
    } else {
        try {
            const dateTime = new Date(DateTimeValue)
            return dateTime.toISOString()
        } catch(error) {
            console.log("error when creating date time from input" + error)
            return undefined
        }
    }
}

export function getLastDayOfYear(year) {
    const today = moment(year)

    return today.endOf("year").format("YYYY-MM-DD");
}

export function getFirstDayOfYear(year) {
    const today = moment(year)

    return today.startOf("year").format("YYYY-MM-DD");
}


export function getLastDayOfMonthFormatted() {
    const today = moment();

    // Get the last day of the next month and subtract one day to get the last day of the current month
    const lastDayOfMonth = today.endOf("month")

    return lastDayOfMonth.format("YYYY-MM-DD");
}

export function getFirstDayOfMonth() {
    const today = moment();

    const firstDay = today.startOf("month");

    return firstDay.format("YYYY-MM-DD");
}

export function getFirstDayOfMonthZT() {
    const today = moment();

    const firstDay = today.startOf("month")

    return firstDay.format("YYYY-MM-DD");
}

export function getFirstDayOfLastMonthZT() {
    const today = moment();

    const firstDay = today.startOf("M").subtract(1, "M")

    return firstDay.format("YYYY-MM-DD");
}